@import './src/frontend_common/Theme.scss';

.update-button {
  @if $enableCustomBtnStyle == 1 {
    background: $primaryButtonBackground;
    color: $primaryButtonColor;
    &:disabled {
      opacity: 0.6;
    }
  } @else {
    background: $primaryP2;
    color: $greyG8;
    &:disabled {
      background: $secondaryS1;
    }
  }

  text-align: center;

  width: 120px;
  padding: 13px 24px;
  border-radius: 100px;
  border: 0px;
  margin-top: 0;

  &:focus {
    outline: 0px;
  }
}
