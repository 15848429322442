@import '../../frontend_common/Theme.scss';

.gender-picker-box {
  width: 342px;
  border-width: 0;
  border-bottom: 1px solid #b18977;
}
.picker-box {
  position: relative;

  &.dropdown-toggle::after {
    display: none;
  }

  .account-input {
    color: $primaryP2;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .account-down-arrow {
      margin-top: 0 !important;
      width: 20px;
      height: 20px;
      cursor: pointer;
      filter: $primaryP2Filter;
    }
  }
}
.dropdown-menu.gender-dropdown {
  width: 100%;
  background-color: $greyG8;
  z-index: 1099; // modal z-index 1050

  .gender-dropdown-item {
    padding: 8px 16px;

    text-align: left;
    color: $greyG3;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $greyG8;
      color: $greyG3;
    }
  }
}
