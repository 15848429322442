@import "../../frontend_common/Theme.scss";

.use-cash-qr-code-modal {
  .modal-dialog-centered {
    margin: 1.75rem auto;
    max-width: 320px;
    > div {
      border: none;
      border-radius: 12px;
    }
  }
  .modal-header {
    background-color: $primaryP1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
    color: $greyG8;
    border: none;
    border-radius: 12px 12px 0 0;

    .cash-modal-secondary-title,
    .cash-point-balance-text {
      color: $greyG8;
    }
    .cash-point-balance-text {
      text-transform: lowercase;
    }
    
    .cash-dollar-text {
      height: 44px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 4px;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px 24px;
    background-color: $greyG8;

    .current-time {
      color: $greyG1;
    }
    .cash-point-balance-text{
      color: $greyG1;
    }

    .point-to-cash-rule {
      color: $greyG3;
    }
  }
  .modal-footer {
    border: none;
    border-radius: 0 0 12px 12px;
    background-color: $greyG8;
    padding: 0 10px 24px;

    .left_btn {
      width: 100%;
      margin: 0;
    }
  }
  
}
.cash-qr-code {
  margin: 8px 0;
  width: 240px;
  height: 240px;
}