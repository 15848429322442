@import "../frontend_common/Theme.scss";

.transaction_breadcrumbs {
    height: 24px;
  }

.transaction_detail_content {
    display: block;
    padding: 0px;
    width: 100%;
    background-color: $greyG8;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
    padding-top: 20px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
    .content_item {
        .content_item_title {
            margin-bottom: 16px;
            color: $greyG2;
        }
        .content_item_value {
            word-break: break-all;
            color: $greyG3;
        }
        .receipt_image_container, .credit_image_container {
            display: flex;
            flex-direction: column;
            
            .image img {
                width: 147.5px;
                height: 147.5px;
                flex-shrink: 0;
            }
            .image_text {
                margin-top: 12px;
                text-align: center;
                color: $greyG3;
            }
        }
        .receipt_image_container {
            margin-right: 16px;
        }
        .receipt_images {
            display: inline-flex;
        }
        .reward_value {
            align-self: stretch;
            color: $primaryP2;
        }
        .reward_value:not(:first-child) {
            margin-top: 16px;
        }
        .reward_campaigns {
            margin-top: 8px;
            display: flex;
            .reward_campaigns_label, .reward_campaigns_name {
                align-self: stretch;
                color: $greyG3;
            }
            .reward_campaigns_label {
                margin-right: 8px;
            }
        }
    }
    .content_item:not(:first-child) {
        margin-top: 28px;
    }
    .btn_delete_receipt {
        display: inline-flex;
        padding: 13px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 100px;
        border: 2px solid $primaryP2;

        color: $primaryP2;
        text-align: center;
    }
    .preview_image {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.5);
        flex-direction: column;
        .preview_image_image {
            max-width: calc(100% - 32px);
            max-height: calc(100% - 96px);
            padding: 0 0 16px 0;
        }
        .close-btn {
            width: 48px;
            height: 48px;
        }
    }
}

@media (max-width: 374px) {
    .transaction_detail_content {
        .receipt_images {
            display: inline-flex;
            flex-direction: column;
            
            .credit_image_container {
                margin-top: 16px;
            }
        }
    }
}
