@import "./src/frontend_common/Theme.scss";

.onlyForYou_mobile_slider_item {
  display: inline-flex;
  position: relative;
  height: 100%;
  width: 84%;

  margin-left: 15px;
}

.last_item {
  margin-right: 15px;
}

.onlyForYou_mobile_slider_item {
  .slider_item_image_container {
    position: absolute;

    top: 0;
    right: 0;
    left: 0;
    // height: 73%;
    // width: 100%;

    border-radius: 8px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.11);
    overflow: hidden;
  }

  .slider_item_describe {
    position: absolute;

    top: 75.5%;
    right: 0;
    left: 0;
    min-height: 44px;
    margin: 0 auto;

    text-align: left;

    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    color: $greyG8;
  }

  .slider_item_button {
    position: absolute;
    height: 32px;
    bottom: 1px;
    right: 1px;
    padding: 13px 16px;
    border-radius: 100px;
    border: none;
    min-width: 161px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $primaryP2;
    background-color: $greyG8;
  }

  .slider_item_button:disabled {
    background-color: $secondaryS1;
    border-color: $secondaryS1;
  }

  .slider_item_progress_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 1px;
    width: 90%;

    label {
      color: $greyG8;
    }

    .slider_item_progress {
      display: flex;
      margin-top: -17px;

      .ant-progress-inner {
        background-color: $greyG6;

        .ant-progress-bg {
          background-color: $secondaryS1;
        }
      }
    }
    .slider_item_progress_gift_icon{
      margin-inline-start: -15px;
      margin-top: -6px;
      background-color: $greyG6;
      border-radius: 100%;
    }
    .slider_item_progress_image {
      height: 40px;
      width: 40px;
      filter: $primaryP2Filter;
    }
  }
}


.slider_item_image_container {
  .slider_item_image_dimmed_container {
    width: 100%;
    height: 100%;
  }

  .slider_item_image_dimmed_container .slider_item_dimmed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $greyG8;
    border-radius: 8px;
    opacity: 0.6;
  }

  .slider_item_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .slider_item_avatar {
    position: absolute;
    height: 15%;
    width: 15%;
    top: 4.5%;
    right: 4.5%;
    object-fit: cover;

    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.15);
  }

  .slider_item_tag {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    left: 4%;
    bottom: 0;
    padding: 2px 8px;
    height: 20px;
    gap:10px;
    background-color: $greyG8;

    text-align: center;
    color: $greyG3;
  }

  .slider_item_out_of_stock_tag_label {
    margin: 0;
    padding: 0;
    color: $greyG8;
  }
}
