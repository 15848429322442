@import "./src/frontend_common/Theme.scss";

.filter_action {
  height: 80px;
  width: 100%;
  display: flex;
  flex-flow: row;
  .middle_space {
    width: 24px;
    height: 48px;
  }
  button {
    flex: 1;
    width: auto;
    height: 48px;
    border-radius: 24px;
    &.btn-primary {
      @if $enableCustomBtnStyle == 1 {
        color: $primaryButtonColor;
        background: $primaryButtonBackground;
      } @else {
        color: $greyG8;
        background: $primaryP2;
      }

      border-color: $primaryP2;
      &:focus, &.focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle {
        border-color: $primaryP2;
        box-shadow: none;
        @if $enableCustomBtnStyle == 1 {
          color: $primaryButtonColor;
          background: $primaryButtonBackground;
        } @else {
          color: $greyG8;
          background: $primaryP2;
        }
      }
    }
    &.btn-secondary {
      color: $primaryP2;
      background-color: $greyG8;
      border-color: $primaryP2;
      &:active, &:focus, &.focus {
        color: $primaryP2;
        background-color: $greyG8;
        border-color: $primaryP2;
        box-shadow: 0 0 0 0.2rem $primaryP2;
      }
      &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle {
        color: $primaryP2;
        background-color: $greyG8;
        border-color: $primaryP2;
      }
    }
  }
}
