@import "./src/frontend_common/Theme.scss";

div.modal_buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  padding: 0;
  border-top: 0;
}
.custom_modal > div {
  justify-content: center;
}
.custom_modal > div > div {
  width: 624px;
  min-height: 186px;
  border: 0px;
  background-color: $greyG8;
  padding: 32px 24px;
  
  .custom-modal-title {
    color: $greyG1;
    text-align: left;
  }

  .modal-body {
    padding: 0;
  }
}
.custom_modal_content {
  text-align: left;
  color: $greyG1;
}
div.modal_message {
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 42px;
}
.left_btn,
.right_btn {
  margin: 0;
  padding: 0px 27px;
  border-radius: 100px;
  border: solid 2px $primaryP2;
  height: 50px;
  min-width: 125px;
  text-align: center;
  &:focus,
  &:active:focus {
    outline: 0;
    box-shadow: none;
  }
}
.left_btn {
  color: $primaryP2;
  background-color: $greyG8;
}
.right_btn {
  @if $enableCustomBtnStyle == 1 {
    background: $primaryButtonBackground;
    color: $primaryButtonColor
  } @else {
    background: $primaryP2;
    color: $greyG8;
  }
  border: none;
}

.loadSpinner_container {
  margin: 54px auto;
  text-align: center;
}

@media (max-width: 1023px) {
  div.custom_modal {
    // background-color: $greyG8;
    width: 100%;
    padding-left: 0px;
  }
  // .custom_modal > div {
  //   align-items: flex-start;
  //   margin-top: 65px;
  // }
  .custom_modal > div > div {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .custom_modal > div > div {
    width: 100%;
    height: auto;
    max-width: 312px;
    min-height: 150px;
    padding: 24px;
    gap: 40px;

    .custom-modal-title {
      text-align: center;
    }
    .custom_modal_content {
      text-align: center;
    }
    .modal_buttons {
      gap: 0;
      justify-content: space-between;
      &.only-one-button {
        justify-content: center;
        & > button {
          width: 100%;
        }
      }
    }
    
    .left_btn,
    .right_btn {
      margin: 0;
      padding: 13px 10px;
      max-height: 52px;
    }
  }
}
