@import "./src/frontend_common/Theme.scss";

.store_filter_modal {
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: $transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: $primaryP2;
    border-radius: 2px;
  }
  .modal-dialog {
    max-width: 400px;
    height: 100%;
    margin: 0 0 auto auto;
    .modal-content {
      border: none;
      height: 100%;
    }
  }
  .store_filter_location_brand {
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: $greyG8;
    display: flex;
    flex-flow: column;
    .filter_title {
      margin-top: 8px;
      height: 56px;
      width: 100%;
      padding: 8px 8px 8px 24px;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      span {
        color: $greyG1;
      }
      img.close {
        height: 44px;
        width: 44px;
        opacity: 1;
        cursor: pointer;
      }
    }
    .region_and_district .filter_title, .brand .filter_title {
      margin: 0;
      padding: 12px 24px;
      cursor: pointer;
      span {
        color: $greyG1;
      }
      img.extend {
        height: 24px;
        width: 24px;
      }
    }
    .filter_content {
      margin-top: 0;
      margin-bottom: 80px;
      width: 100%;
      height: auto;
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      overflow-x: hidden;
      overflow-y: scroll;
      .filter_location {
        width: 100%;
        max-height: 280px;
        .multi_choice_with_sub_list {
          margin-left: 8px;
          width: auto;
        }
      }
      .filter_brand {
        margin-left: 0;
        width: 100%;
      }
      .filter_separate {
        margin-left: 24px;
        margin-right: 24px;
        height: 1px;
        min-height: 1px;
      }
      .region_and_district, .brand {
        display: flex;
        flex-flow: column;
      }
      .filter_brand {
        padding: 8px 4px 4px 24px;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 12px;
        flex-wrap: wrap;
        button {
          padding: 0 24px;
          width: auto;
          max-width: 100%;
          height: 40px;
          border-radius: 20px;

          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .custom_separate.action_bar_border_top {
      bottom: 80px;
      position: absolute;
      width: 100%;
      height: 1px;
      &:after {
        background-color: $greyG5;
      }
    }
    .filter_action {
      bottom: 0;
      position: absolute;
      padding: 16px 24px;
    }
  }
  button.btn-primary {
    color: $greyG8;
    background-color: $primaryP2;
    border-color: $primaryP2;
    &:focus, &.focus {
      color: $greyG8;
      background-color: $primaryP2;
      border-color: $primaryP2;
      box-shadow: 0 0 0 0.2rem $primaryP2;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      color: $greyG8;
      background-color: $primaryP2;
      border-color: $primaryP2;
    }
  }
  button.btn-secondary {
    color: $primaryP2;
    background-color: $greyG8;
    border-color: $primaryP2;
    &:active, &:focus, &.focus {
      color: $primaryP2;
      background-color: $greyG8;
      border-color: $primaryP2;
      box-shadow: 0 0 0 0.2rem $primaryP2;
    }
    &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      color: $primaryP2;
      background-color: $greyG8;
      border-color: $primaryP2;
    }
  }
  button.btn-outline-secondary {
    color: $greyG3;
    background-color: $greyG8;
    border-color: $greyG4;
    &:active, &:focus, &.focus {
      color: $greyG3;
      background-color: $greyG8;
      border-color: $greyG4;
      box-shadow: 0 0 0 0.2rem $primaryP2;
    }
  }
}


@media (max-width: 1023px) {
  .store_filter_modal {
    .modal-dialog {
      max-width: 300px;
    }
  }
}
