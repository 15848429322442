@import "../../frontend_common/Theme.scss";

.level-section-content {
  width: 400px;
  height: 216px;
  color: $primaryP1;

  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;

  text-align: left;

  &.max-level {
    color: $greyG8;
  }

  .level-section-header {
    align-items: flex-start;
    justify-content: space-between;

    .qrcode-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
      background-color: $primaryP2;
      padding: auto auto;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .level-text {
    margin-top: 4px;
  }
  .spend-to-upgrade {
    text-align: left;
    > p {
      display: inline-block;
      padding: 0;
      margin: 0;
    }
  }

}

.level-bg {
  width: 400px;
  height: 216px;
  position: absolute;
  z-index: 0;
  border-radius: 8px;
  background:$greyG8;
}

.level-1-color,
.level-2-color,
.level-3-color {
  opacity: 1;
  background-color: $primaryP2;
}
.level-4-color {
  opacity: 1;
  background-color: $primaryP1;
}

.level-1-color {
  opacity: 0.3;
}

.level-2-color {
  opacity: 0.5;
}

.level-3-color {
  opacity: 0.8;
}

.level-4-text-color {
  color: $greyG8;
}

.level-section-content div {
  z-index: 1;
}
.upgrade-progress {
  margin: 0;
  .ant-progress-inner {
    background-color: $greyG8;
  }
  .ant-progress-bg {
    background-color: $primaryP1 !important;
  }
}