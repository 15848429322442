@import "../../frontend_common/Theme.scss";

.reward-status-section {
  margin-top: 32px;
  .section-header {
    margin: 0 -15px 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .view-all {
      border: none;
      background-color: inherit;
      padding: 0px;
      color: $primaryP2;

      cursor: pointer;
    }
  }

  div.row {
    gap: 24px;
  }
  .col {
    margin: 0px;
    padding: 0px;
  }
}

.reward_status_table_view {
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: none;
  background-color: $greyG8;
  .reward_status_table_head {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    justify-content: space-between;
    background-color: $greyG5;
    border-radius: 8px 8px 0 0;

    .reward_status_table_head_item {
      color: $greyG2;

      &:nth-last-child() {
        text-align: end;
      }
    }
  }
}
.reward-status-table-body {
  overflow: hidden;
  border-radius: 0 0 8px 8px;

  &.scroll-table {
    max-height: 340px;
  }
}

.reward-status-table.table {
  margin: 0;

  > tbody {
    overflow-y: scroll;
  }

  tr {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    border-top: 1px;
    min-height: 68px;
    align-items: center;
    
    &:not(:last-child) {
      border-bottom: 1px solid $greyG3;
    }
  }

  tr.tr-stamp {
    cursor: pointer;
    padding: 11px 24px 8px 24px;
  }

  .no-record-tr {
    justify-content: center;
    .no_record_message {
      margin: 0;
      width: 100%;
      text-align: center;
      color: $greyG2;
      > a {
        color: $primaryP2;
      }
    }
  }

  .item_date,
  .item_name {
    padding: 0;
  }

  .item_name {
    max-width: 60%;
    color: $primaryP2;

    div,
    div > label {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .earned_stamps {
      color: $greyG2;
    }

    .stamp_campaign_name {
      margin-top: 4px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-left: auto;
        margin-right: 0;
        height: 16px;
        width: 16px;
        filter: $primaryP2Filter;
      }
    }
  }

  .item_name.stamp-status {
    max-width: 75%;
    width: 75%;
  }

  .item_date {
    color: $greyG3;
  }
}

.reward-status-section.mobile-view {
  margin-top: 0px;
  .reward_status_table_view {
    margin-bottom: 12px;
    .item_date {
      text-align: right;
    }
  }
}