@import "../frontend_common/Theme.scss";

@media (max-width: 575px) {
    div.offer_container {
        padding-left: 0px;
        padding-right: 0px;
    }
}

div.offer_none_padding {
    padding: 0px;
    .custom-nav-dropdown-container {
        background-color: $greyG8;
    }
}
