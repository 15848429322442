$primaryP1: rgba(156, 140, 79, 1); // Gold
$primaryP2: rgba(177, 162, 111, 1); // Light gold
$secondaryS1: rgba(177, 162, 111, 0.6); // light gold
$secondaryS2: rgba(177, 162, 111, 1); // Light gold
$secondaryS3: rgba(17, 178, 170, 1); // Cyan
$secondaryS4: rgba(239, 104, 104, 1); // Red
$greyG1: rgba(0, 0, 0, 1); // Black
$greyG2: rgba(62, 62, 62, 1); // Dark grey
$greyG3: rgba(106, 111, 115, 1); // Grey
$greyG4: rgba(177, 177, 177, 1); // Light Grey
$greyG5: rgba(225, 223, 224, 1); // Grey for line
$greyG6: rgba(243, 243, 244, 1); // Super light grey
$greyG7: rgba(250, 250, 250, 1); // Grey for BG
$greyG8: rgba(255, 255, 255, 1); // White
$opacityOP1: rgba(0, 0, 0, 0.5);
$opacityOP2: rgba(106, 111, 115, 0.95);

// online filter generator: https://angel-rs.github.io/css-color-filter-generator/
$primaryP2HueRotate: brightness(0) saturate(100%) invert(69%) sepia(84%) saturate(169%) hue-rotate(9deg) brightness(80%) contrast(84%);

$primaryP1Filter: brightness(0) saturate(100%) invert(54%) sepia(53%) saturate(355%) hue-rotate(10deg) brightness(92%) contrast(85%);
$primaryP2Filter: brightness(0) saturate(100%) invert(69%) sepia(84%) saturate(169%) hue-rotate(9deg) brightness(80%) contrast(84%);
$secondaryS1Filter: brightness(0) saturate(100%) invert(69%) sepia(35%) saturate(354%) hue-rotate(9deg) brightness(87%) contrast(90%);
$secondaryS2Filter: brightness(0) saturate(100%) invert(69%) sepia(84%) saturate(169%) hue-rotate(9deg) brightness(80%) contrast(84%);
$secondaryS3Filter: brightness(0) saturate(100%) invert(56%) sepia(54%) saturate(707%) hue-rotate(128deg) brightness(90%) contrast(87%);
$secondaryS4Filter: brightness(0) saturate(100%) invert(73%) sepia(34%) saturate(7120%) hue-rotate(317deg) brightness(95%) contrast(98%);
$greyG1Filter: brightness(0) saturate(100%) invert(0%) sepia(94%) saturate(26%) hue-rotate(82deg) brightness(104%) contrast(106%);
$greyG2Filter: brightness(0) saturate(100%) invert(19%) sepia(1%) saturate(0%) hue-rotate(249deg) brightness(92%) contrast(79%);
$greyG3Filter: brightness(0) saturate(100%) invert(44%) sepia(10%) saturate(206%) hue-rotate(164deg) brightness(94%) contrast(91%);
$greyG4Filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(0%) hue-rotate(176deg) brightness(88%) contrast(84%);
$greyG5Filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(1102%) hue-rotate(280deg) brightness(93%) contrast(88%);
$greyG6Filter: brightness(0) saturate(100%) invert(95%) sepia(3%) saturate(47%) hue-rotate(202deg) brightness(102%) contrast(94%);
$greyG7Filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(0%) hue-rotate(261deg) brightness(104%) contrast(104%);
$greyG8Filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);

// page navigater style
$navigatorBackground: $greyG8;
$navigatorTextColor: $primaryP1;
$navigatorTextActiveColor: rgba($primaryP1, 0.6);
$signupBtnBackground: $primaryP2;
$signupBtnColor: $greyG8;
$logoColor: $primaryP1;

$enableCustomBtnStyle: 1;
$primaryButtonBackground: $primaryP1;
$primaryButtonColor: $greyG8;
$secondaryButtonBackground: $secondaryS2;
$secondaryButtonColor: $greyG8;
