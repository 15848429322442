@import "../frontend_common/Theme.scss";

div.home_container {
  padding: 0;
}

div.home_onboard_container {
  background-color: $greyG8;
}
.home_only_for_you_section {
  margin-top: 48px;

  &.no-banner {
    margin-top: 0;
  }
}

.home_title_container {
  padding-left: 15px;

  color: $primaryP1;
}
.home_login_banner {
  width: 100%;
  position: sticky;
  top: calc(var(--app-banner-height) + var(--page-nav-height));
  left: 0px;
  z-index: 4;
  color: $greyG8;
  padding: 0;

  .home_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    background-color: $primaryP1;
  }
  .signup {
    margin: 0 13px 0 0;
    padding: 9px 16px;
    background-color: $primaryP2;
    white-space: nowrap;
  }

  .home_signup_title {
    margin-left: 13px;
  }
}

@media (max-width: 575px) {
  div.home_card_container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .home_title_container {
    padding-bottom: 12px;
  }
  .home_only_for_you_section {
    margin-top: 48px;
  }
  .home_login_banner {
    .home_signup_title {
    }
  }
}
