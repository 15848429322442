@import "./src/frontend_common/Theme.scss";

.filter_dropdown_content {
  width: 400px;
  height: 400px;
  color: $greyG3;
  z-index: $zIndexFloatingTitle;
  &.card {
    border: none;
    border-radius: 8px;
    background-color: $greyG8;
    box-shadow: 0 3px 5px 0 $opacityOP2;
  }
  .card-body {
    margin-top: 8px;
    height: 312px;
    padding: 0px;
    background-color: $greyG8;
    flex-flow: row;
    display: flex;
  }
  .card-footer {
    height: 80px;
    padding: 16px;
    background-color: $greyG8;
    border-top: 1px solid $greyG5;
    .btn {
      width: 100%;
      height: 48px;
      border-radius: 24px;
      &.btn-primary {
        color: $greyG8;
        background-color: $primaryP2;
        border-color: $primaryP2;
      }
    }
  }
}

