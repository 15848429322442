// use for en language
@font-face {
    font-family: "OpenSans-Regular";
    src: url("../assets/fonts/OpenSans.ttf");
}
@font-face {
    font-family: "OpenSans-Medium";
    src: url("../assets/fonts/OpenSans-Medium.ttf");
}
@font-face {
    font-family: "OpenSans-SemiBold";
    src: url("../assets/fonts/OpenSans-Medium.ttf");
}
@font-face {
    font-family: "OpenSans-Bold";
    src: url("../assets/fonts/OpenSans-Bold.ttf");
}
@font-face {
    font-family: "OpenSans-ExtraBold";
    src: url("../assets/fonts/OpenSans-ExtraBold.ttf");
}
@font-face {
    font-family: "OpenSans-Black";
    src: url("../../src/assets/fonts/OpenSans-ExtraBold.ttf");
}

// // use for sc language
@font-face {
    font-family: "OpenSans-Black";
    src: url("../assets/fonts/OpenSans-ExtraBold.ttf");
}
@font-face {
    font-family: "OpenSans-Bold";
    src: url("../assets/fonts/OpenSans-Bold.ttf");
}
@font-face {
    font-family: "OpenSans-Medium";
    src: url("../assets/fonts/OpenSans-Medium.ttf");
}
@font-face {
    font-family: "OpenSans-Regular";
    src: url("../assets/fonts/OpenSans.ttf");
}

// // use for tc language
@font-face {
    font-family: "OpenSans-Black";
    src: url("../assets/fonts/OpenSans-ExtraBold.ttf");
}
@font-face {
    font-family: "OpenSans-Bold";
    src: url("../assets/fonts/OpenSans-Bold.ttf");
}
@font-face {
    font-family: "OpenSans-Medium";
    src: url("../assets/fonts/OpenSans-Medium.ttf");
}
@font-face {
    font-family: "OpenSans-Regular";
    src: url("../assets/fonts/OpenSans.ttf");
}

.title-x-large {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        line-height: 46px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        line-height: 46px;
    }
}

.title-large {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        line-height: 42px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        line-height: 42px;
    }
}

.title-medium-1 {
    font-family: "OpenSans-SemiBold";
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Medium";
        font-weight: 500;
        line-height: 36px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Medium";
        font-weight: 500;
        line-height: 36px;
    }
}

.title-medium-2 {
    font-family: "OpenSans-ExtraBold";
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 36px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 36px;
    }
}

.title-small {
    font-family: "OpenSans-ExtraBold";
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 28px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 28px;
    }
}

.headline {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        line-height: 26px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        line-height: 26px;
    }
}

.subheading {
    font-family: "OpenSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 24px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 24px;
    }
}

.field-text {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Regular";
        line-height: 24px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Regular";
        line-height: 24px;
    }
}

.body-1 {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Medium";
        font-weight: 400;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Medium";
        font-weight: 400;
        line-height: 22px;
    }
}

.body-2 {
    font-family: "OpenSans-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 22px;
    }
}

.body-3 {
    font-family: "OpenSans-Bold";
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Black";
        font-weight: 900;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Black";
        font-weight: 900;
        line-height: 22px;
    }
}

.button-large {
    font-family: "OpenSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Medium";
        font-weight: 500;
        line-height: 22px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Medium";
        font-weight: 500;
        line-height: 22px;
    }
}

.button-small {
    font-family: "OpenSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Medium";
        font-weight: 500;
        line-height: 20px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Medium";
        font-weight: 500;
        line-height: 20px;
    }
}

.tag-menu-1 {
    font-family: "OpenSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Regular";
        line-height: 16px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Regular";
        line-height: 16px;
    }
}

.tag-menu-2 {
    font-family: "OpenSans-SemiBold";
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 16px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 16px;
    }
}

.caption {
    font-family: "OpenSans-Medium";
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Medium";
        line-height: 14px;
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Medium";
        line-height: 14px;
    }
}


// home section title font
.onlyForYou_mobile_title,
.home_title_container {
    font-family: "OpenSans-ExtraBold";
    font-weight: 800;
    line-height: 28px;
    font-size: 20px;
    letter-spacing: 0;
    &:lang(zh-Hans) {
        font-family: "OpenSans-Bold";
    }
    &:lang(zh-Hant) {
        font-family: "OpenSans-Bold";
    }
}

@media (min-width: 768px) {
    // home section title font
    .onlyForYou_web_title,
    .home_title_container {
        font-family: "OpenSans-Bold";
        font-weight: 700;
        line-height: 44px;
        font-size: 32px;
        letter-spacing: 0;
        &:lang(zh-Hans) {
            font-family: "OpenSans-Bold";
            line-height: 46px;
        }
        &:lang(zh-Hant) {
            font-family: "OpenSans-Bold";
            line-height: 46px;
        }
    }
}