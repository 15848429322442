@import './src/frontend_common/Theme.scss';

.onlyForYou_mobile_container {
    position: relative;
    width: 100%;
    padding: 141% 0 0;
}

.onlyForYou_mobile_background_blue {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 60%;

    background-color: $primaryP2;
}

.onlyForYou_mobile_title {
    position: absolute;
    top: 1.5%;
    left: 4%;

    color: $primaryP1;
}

.onlyForYou_mobile_slider_container {
    position: absolute;
    top: 10%;
    right: 0;
    left: 0;
    bottom: 4.3%;
    width: 100%;
    overflow-x: visible;
    overflow-y: hidden;
    scrollbar-width: none;
    white-space: nowrap;
}

.onlyForYou_mobile_slider_container::-webkit-scrollbar {
    display: none;
}

