@import './src/frontend_common/Theme.scss';

.inbox_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  margin-top: $containerMarginTop;
}

.inbox_detail_content {
  display: block;
  padding: 0px;
  width: 100%;
  background-color: $greyG8;
  border-radius: 8px;
  box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
  padding-top: 20px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
  .content_title {
    color: $greyG1;
  }
  .content_date {
    margin-top: 15px;
    color: $greyG3;;
  }
  .content_content {
    margin-top: 15px;
    margin-bottom: 40px;
    color: $greyG3;
  }
  .content_image {
    margin-top: 12px;
    width: 100%;
  }
  button.content_button {
    height: 50px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 25px;
    border: none;
    background-color: $primaryP2;
    box-shadow: none;

    &:active,
    &:focus:active {
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  .inbox_detail_content {
    button.content_button {
      width: 100%;
    }
  }
}
