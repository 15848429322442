@import './src/frontend_common/Theme.scss';

.transaction_history_detail {
  background-color: $greyG6;
  .columnContainer {
    padding-left: $containerMarginLeft;
    padding-right: $containerMarginRight;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .rowContainer {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: space-between;
  }
  .rowKey {
    color: $greyG3;
  }
  .rowValue {
    color: $greyG1;
  }
  .rowKey,
  .rowValue {
    &.refundColor {
      color: $secondaryS3;
    }
    &.totalPriceColor {
      color: $primaryP2;
    }
    &.totalPriceText {
    }
  }
  .cardView {
    margin-top: 16px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: $greyG8;
    padding-top: $containerMarginTop;
    padding-bottom: $containerMarginTop;
    padding-left: $containerMarginLeft;
    padding-right: $containerMarginRight;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .columnLabel {
    color: $primaryP1;
  }
  .columnContent {
    color: $greyG3;
  }
  .divider {
    margin-top: 8px;
    width: 100%;
    height: 1px;
    background-color: $greyG5;
  }
  .defaultRowSpace {
    margin-top: 8px;
  }
}
