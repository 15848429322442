@import "../../frontend_common/Theme.scss";

.level-benefit-mobile-page {
  background-color: $greyG8;
  .home-profile-custom-tab {
    gap: 24px;
    .nav-item > a {
      margin: 16px 0;
      padding: 0 0 4px 0;

      color: $greyG4;

      &.active {
        color: $primaryP1;
        border-bottom: 2px solid $primaryP1;
      }
    }
  }
  .tab-content {
      padding-bottom: 24px;
    }
}