@import "../../frontend_common/Theme.scss";

.stock_container {
  margin: 0px 20px;
}
.stock_title {
  color: $greyG3;
  margin-top: 18px;
}
div.left_stock {
  padding-left: 5px;
}
.right_stock {
  align-self: center;
  border-left: solid 1px $greyG5;
  position: absolute;
  right: 0;
  padding-left: 33px;
  padding-right: 33px;
}
.update_time {
  color: $greyG3;
  margin-bottom: 18px;
}
.left_num {
  color: $primaryP2;
}
.coupon-icon {
  margin-top: 23px;
  filter: $greyG3Filter;
}
@media (max-width: 1023px) {
  .stock_container {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .right_stock {
    padding-left: 15px;
    padding-right: 15px;
  }
}
