@import "../../frontend_common/Theme.scss";
.nothing-area {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nothing-area img {
  margin: auto;
}

.nothing-title {
  color: $greyG2;
}

.nothing-content {
  color: $greyG3;
}

.back-home-button {
  width: 176px;
  height: 50px;
  padding: 12px 27px;
  background-color: $primaryP2;
  margin: auto;
  border-radius: 25px;

  border: none;
  outline: none !important;
}

.back-home-button label {
  color: $greyG8;
  cursor: pointer;
}

a.a-no-href,
a.a-no-href:hover {
  color: #e27b2e;
  text-decoration: underline;
}

.browser-icon-area {
  margin-top: 56px;
  margin-bottom: 56px;

  .browser-icon-space {
    margin-left: 30px;
  }
}
