@import "../../frontend_common/Theme.scss";

.pick_up_section {
  width: 100%;
  min-height: 63px;
  background-color: $greyG8;
  margin-bottom: 24px;
}
.pick-up-store-back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: $greyG8;
  border-radius: 100px;
  margin-bottom: 24px;
  cursor: pointer;
  > img {
    width: 24px;
    height: 24px;
    filter: $primaryP2Filter;
  }
}

.pick_up_div {
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;

  .split-line {
    margin: 0 16px;
    height: 1px;
    background-color: $greyG5;
  }

  .confirm-selected-store {
    margin: 0 16px;
    .store_name {
      margin: 0;
    }
  }
}
.conform-store-tips {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  color: $greyG3;

  > img {
    width: 40px;
    height: 40px;
    filter: $greyG3Filter;
  }
}
button.comfirm-select-store-btn {
  @if $enableCustomBtnStyle ==1 {
    background: $primaryButtonBackground;
    color: $primaryButtonColor;
    &:disabled {
      opacity: 0.6;
    }
  } @else {
    background: $primaryP2;
    color: $greyG8;
    &:disabled {
      background: $secondaryS1;
    }
  }
  width: 100%;
  min-height: 48px;
  padding: 13px 10px;

  text-align: center;

  border-radius: 100px;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }

}

.store_list {
  width: 100%;
  max-height: 598px;
  // .store-list-item {
  //   padding: 0;
  // }
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primaryP2;
    border-radius: 2px;
  }
  // .first {
  //   padding-top: 15px;
  // }
  // .last {
  //   padding-bottom: 15px;
  // }
}

.store-list-item {
  display: flex!important;
  flex-direction: row;
}

.store-list-item-button {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-top: 9px;
  margin-right: 9px;
}

.available-icon-filter {
  filter: $primaryP2Filter;
}

.unavailable-icon-filter {
  filter: $primaryP2Filter;
  opacity: 0.6;
}

.pick_up_label {
  color: $primaryP1;
  margin: 0 0 0 16px;
}

.store_name {
  color: $greyG1;
  margin-bottom: 0;
  margin-top: 9px;
}
.store_location {
  width: 100%;
  color: $greyG3;
}
span.available_state_label {
  border-radius: 4px;
  border: solid 1px $primaryP2;
  margin-left: 0px;
  // margin-bottom: 16px;
  color: $primaryP2;
  background-color: $greyG8;
}
span.online-store-label,
span.offline-store-label {
  border-radius: 4px;
  border: none;
  color: $greyG8;
  background-color: $primaryP2;
  width: fit-content;
}
span.online-store-label {
  background-color: $primaryP1;
}

div.list-group-item {
  width: 100%;
  border: 0;
  padding: 0 16px 7px;
  background-color: $greyG8;
  &:hover {
    background-color: $greyG6;
  }
}

.location {
  color: $greyG3;
  margin-left: 1px;
  word-wrap:break-word;
  word-break: break-all;
}

.bottom-select-store-btn-container.staff_use_bottom_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $greyG8;

  .conform-store-tips {
    max-width: 166px;
  }

  &:has(.conform-store-tips) .comfirm-select-store-btn{
    width: auto;
    min-width: 158px;
  }
}

.mobile-view-pick-up-store-container {
  margin-top: 16px;
  padding-bottom: 74px;

  .pick_up_section {
    border-radius: 8px;
  }
  .store_list {
    min-height: 90px;
    max-height: calc(100vh - var(--page-nav-height) - var(--app-banner-height) - 48px - 24px - 22px - 16px - 16px - 74px);
  }
}
