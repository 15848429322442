@import "./src/frontend_common/Theme.scss";

.home_cookie {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-height: 67px;
  box-shadow: 0 -2px 8px 0 rgba(124, 124, 124, 0.5);
  background-color: $greyG8;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: $zIndexAppNav;

  .home_cookie_textArea {
    display: flex;
    align-items: center;
    .home_cookie_text {
      display: flex;
      margin: 24px;
      > label {
        margin: 0;
      }

      .text_link {
        color: $primaryP2;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .home_cookie_buttonArea {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0 24px 24px 24px;
    gap: 24px;
    
    > button {
      padding: 13px 10px;
      border-radius: 100px;
      // max-height: 48px;
      width: 200px;
      box-shadow: none;
      outline: none;
    } 
    button.buttonArea_accept {
      border: none;
      @if $enableCustomBtnStyle == 1 {
        background: $primaryButtonBackground;
        color: $primaryButtonColor;
        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &:visited {
          box-shadow: none;
          background: $primaryButtonBackground;
        }
      } @else {
        background: $primaryP2;
        color: $greyG8;
        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &:visited {
          box-shadow: none;
          background: $primaryP2;
        }
      }
    }
    button.buttonArea_reject {
      border: 2px solid $primaryP2;
      background-color: $greyG8;
      color: $primaryP2;

      &:hover,
      &:focus,
      &:active,
      &:active:focus,
      &:visited {
        box-shadow: none;
        background-color: $greyG8;
        color: $primaryP2;
        border: 2px solid $primaryP2;
      }
    }
  }
}

.cookie_alert {
  .modal-dialog {
    width: 364px;
    .modal-content {
      .alert_textArea {
        margin-top: 41px;
        margin-right: 14%;
        margin-left: 14%;

        text-align: center;
        color: #2d2d2d;
      }
      .alert_buttonArea {
        margin-top: 27px;
        margin-bottom: 48px;
        margin-right: 14%;
        margin-left: 14%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button_cancel {
          width: 113px;
          height: 50px;

          background-color: transparent;
          color: $primaryP2;

          border-width: 2px;
          border-radius: 25px;
          border-color: $primaryP2;
        }

        .button_ok {
          width: 113px;
          height: 50px;

          background-color: $primaryP2;
          color: $greyG8;

          border-width: 2px;
          border-radius: 25px;
          border-color: $primaryP2;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .cookie_alert {
    .modal-dialog {
      width: auto;
    }
  }
  .home_cookie .home_cookie_textArea .home_cookie_text {
    margin: 16px;
    > label {
      text-align: center;
    }
  }
  .home_cookie .home_cookie_buttonArea  {
    flex-flow: column-reverse;
    gap: 16px;
    margin: 0 16px 16px;
    > button {
          min-width: 100%;
    }
  }
}
