@import "../../frontend_common/Theme.scss";

.spinner_container {
    margin: auto;
    width: 150px;
    text-align: center;

  div.ball_bounce1 {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
      margin: 4px;
  }
  
  div.ball_bounce2 {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
      margin: 4px;
  
  }
  
  div.ball_bounce3 {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
      margin: 4px;
  
  }
}

.spinner_container > div {
    width: 14px;
    height: 14px;
    background-color: $greyG4;
  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.8s infinite ease-in-out both;
    animation: sk-bouncedelay 1.8s infinite ease-in-out both;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.7) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
