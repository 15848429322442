@import './src/frontend_common/Theme.scss';

.inbox_floating_title {
  width: 100%;
  height: 81px;
}

.inbox_list_separateLine {
  width: 100%;
  height: 1px;
  background-color: $greyG5;
}

.inbox_mobile_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  margin-top: $containerMarginTop;
  .inbox_list {
    padding: 0px;
    height: 800px;
    width: 38.5%;
    box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: $transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: $primaryP2;
      border-radius: 2px;
    }
    > .inbox_list_title {
      width: 100%;
      height: 81px;
      border-radius: 8px 8px 0 0;
      background-color: $greyG8;
    }
    > .inbox_list_listArea {
      width: 100%;
      height: 718px;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: $greyG8;
      border-radius: 0 0 8px 8px;
      .nothing-area {
        margin-top: 260px;
      }
      .inbox_list_loadmoreArea {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 576px) {
  .inbox_floating_title > .container {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .inbox_floating_title {
    display: flex;
    flex-flow: column;
    position: fixed;
    top: calc(var(--page-nav-height) + var(--app-banner-height));
    right: $containerMarginRight;
    left: $containerMarginLeft;
    height: auto;
    width: auto;
    padding-top: $containerMarginTop;
    background-color: $greyG7;
    z-index: $zIndexFloatingTitle;
  }
  .inbox_mobile_container {
    .inbox_list {
      width: 100%;
      height: auto;
      .inbox_list_listArea {
        height: auto;
        .nothing-area {
          margin-top: 40px;
        }
        .inbox_list_messageItem {
          .messageItem_active {
            display: none;
          }
        }
      }
    }
    .inbox_content {
      display: none;
    }
  }
}
