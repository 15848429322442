@import "../../frontend_common/Theme.scss";

div.container.nav_container {
  height: var(--page-nav-height);
  padding-top: 9px;
  padding-left: 0px;
  padding-right: 6px;
  svg.dropDown_image {
    height: 45px;
    width: 45px;
    object-fit: cover;
    cursor: pointer;
    z-index: $zIndexAppNav;

    & path {
      stroke: $logoColor;
    }
  }
  .nav_mobile_dropDownMenu {
    position: fixed;
    top: calc(var(--page-nav-height) + var(--app-banner-height));
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    overflow: auto;
    background-color: $greyG8;
    overscroll-behavior: contain;
    .nav_dropDownMenu_content {
      position: relative;
      padding: 0px;
      padding-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      z-index: $zIndexAppNav;
      .nav_dropDownMenu_item_container {
        // height: 52px;
        margin: 10px 0;
        cursor: pointer;
        &:hover {
          background-color: rgba(199, 199, 199, 0.95);
        }
        .nav_dropDownMenu_item {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          .dropDownItem_image {
            height: 24px;
            width: 24px;
            object-fit: cover;
            filter: $primaryP2Filter;
          }
          .dropDownItem_text {
            margin-left: 14px;
            color: $greyG3;
            margin-top: 1px;
          }
        }
        .dropDownItem_blankArea {
          height: 32px;
        }
      }
      .nav_dropDownMenu_separateLine_container {
        // height: 28px;
        margin: 14px 0px;
        display: flex;
        // align-items: center;
        // justify-content: center;
        .nav_dropDownMenu_separateLine {
          width: 100%;
          height: 2px;
          .separateLine_color {
            width: 100%;
            height: 100%;
            background-color: $greyG5;
          }
        }
      }
    }
  }
  .nav_bar {
    .nav_web_dropDownMenu {
      position: fixed;
      top: calc(var(--page-nav-height) + var(--app-banner-height));
      width: 183px;
      padding-top: 5px;
      padding-bottom: 10px;
      margin-left: 25px;
      border-radius: 8px;
      box-shadow: 0 2px 12px 0 rgba(25, 25, 25, 0.24);
      background-color: $greyG8;

      flex-direction: column;
      overflow: hidden;
      .nav_dropDownMenu_item_container {
        display: flex;
        height: 40px;
        cursor: pointer;
        &:hover {
          background-color: rgba(199, 199, 199, 0.95);
        }
        .nav_dropDownMenu_item {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          .dropDownItem_image {
            height: 24px;
            width: 24px;
            object-fit: cover;
            filter: $primaryP2Filter;
          }
          .dropDownItem_text {
            margin-left: 10px;
            color: $greyG3;
          }
        }
        .dropDownItem_blankArea {
          height: 14px;
          width: 14px;
        }
      }

      .nav_dropDownMenu_separateLine_container {
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        .nav_dropDownMenu_separateLine {
          width: 100%;
          height: 2px;
          .separateLine_color {
            width: 100%;
            height: 100%;
            background-color: $greyG5;
          }
        }
      }
    }
  }
}

.logo {
  &:hover {
    cursor: pointer;
  }
  height: 50px;
  margin-left: 16px;

  > path {
    fill: $logoColor
  } 
}

@media (max-width: 1023px) {
  .logo {
    height: 40px;
  }
}

.navigator_back_btn {
  margin-top: 16px;
  margin-left: 10px;
  display: none;
  &:hover {
    cursor: pointer;
  }
}

.nav_bar {
  height: 100%;
  align-items: center;
  margin-right: 10px;
}

.nav_btn {
  padding: 0.5rem 1rem;
  cursor: pointer;
  &.active {
    // color: $primaryP2;
    opacity: 0.6;
    cursor: default;
  }
}

.nav_div_sperate {
  color: $greyishBrown;
  margin-left: 20px;
  border: 1px solid $greyishBrown;
  width: 1px;
}

.nav_div {
  color: $navigatorTextColor;
  cursor: pointer;
  padding: 4px 10px;

  &:hover {
    color: $navigatorTextActiveColor;
  }
  &.active {
    color: $navigatorTextActiveColor;
    cursor: default;
  }
}

.nav_div_trans {
  color: $navigatorTextColor;
  margin-left: 20px;

  label {
    cursor: pointer;
    margin: auto;
  }

  label.active,
  label:active,
  label:hover {
    color: $navigatorTextActiveColor;
  }
}

.signup {
  border-radius: 25px;
  &, &:hover {
    color: $signupBtnColor;
    background: $signupBtnBackground;
  }
  margin-left: 20px;
}

.inbox-unread {
  position: absolute;
  top: 10px;
  margin-left: 55px;
  color: red;
}

.mobile-signup-login-area {
  width: 100%;

  img.signup-login-logo {
    width: 100vw;
    height: 66vw;
    margin-left: -2px;
  }

  div.signup-login-button-area {
    margin-top: 18px;
    padding: 0 12px;
    margin-bottom: 19px;
  }

  div.signup-login-button-area > button {
    width: 100%;
    padding: 14px 20px;
    border-radius: 24px;
    border: 0px;
    background: $primaryButtonBackground;
    text-align: center;

    color: $greyG8;

    outline: none;
  }
}

.go-back-button {
  width: 44px;
  height: 44px;
  margin-left: 0;
}

.top-left-text {
  margin-left: 16px;
  color: $logoColor;
  overflow: hidden;
  word-break:break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 400px;
  text-overflow: ellipsis;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.nav_row {
  display: flex;
  gap: 8px;
  color: $greyG3;
  margin-bottom: 10px;
  width: 100%;

  .nav_row_span {
    color: $primaryP1;
    margin: 0 8px;
  }
}

div.nav_row a {
  color: $greyG3;
  text-decoration: none;
  margin: 0 8px;
}

div.nav_row a:hover {
  color: $primaryP2;
}

@media (max-width: 1200px) {
  .top-left-text {
    max-width: 260px;
  }
}

@media (max-width: 767px) {
  .nav_row,
  .hide_logo {
    display: none !important;
  }
  .navigator_back_btn {
    display: inline;
    width: 24px;
    height: 24px;
    & > path {
      stroke: $logoColor;
    }
  }
  .top-left-text {
    max-width: calc(100% / 2)
  }
}
