@import "../../frontend_common/Theme.scss";

.buy-coupon-modal {
  background-color: $opacityOP1;
  & > div {
    margin: 0 0 auto auto;
    max-width: 348px;
    border-radius: 0;
    min-height: 100%;
    
    > div {
      border: none;
      border-radius: 0;
      min-height: 100vh;
      background-color: $greyG7;

      > div {
        padding: 24px;
      }
    }

    .point_required_section {
      margin: 0;
    }
  }
}