@import '../../frontend_common/Theme.scss';

.offer_floatButton_container {
  margin: 0 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 15px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 4.5%;
  z-index: 5;
  width: 75px;
}

@media (min-width: 1024px) {
  #offer_floatButton {
    bottom: 100px;
    padding: 0px;
  }
}

#offer_floatButton {
  background-color: $primaryP2;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  width: 60px;
  height: 60px;
  padding: 0;
}

.offer_floatButton_image {
  width: 60px;
  height: 60px;
}
