@import './src/frontend_common/Theme.scss';

.inbox_content,
.inbox_detail_content {
  display: block;
  padding: 0px;
  width: 59%;
  background-color: $greyG8;
  border-radius: 8px;
  box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
  padding-top: 20px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
  .content_title {
    color: $greyG1;
  }
  .content_date {
    margin-top: 15px;
    color: $greyG3;
  }
  .content_content {
    margin-top: 15px;
    margin-bottom: 40px;
    color: $greyG3;
  }
  .content_image {
    margin-top: 12px;
    width: 100%;
    border-radius: 8px;
  }
  button.content_button {
    height: 50px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 25px;
    border: none;
    box-shadow: none;
    @if $enableCustomBtnStyle == 1 {
      &, &:not(:disabled):not(.disabled):active {
        background: $primaryButtonBackground;
        color: $primaryButtonColor;
      }
    } @else {
      &, &:not(:disabled):not(.disabled):active {
        color: $greyG8;
        background: $primaryP2;
      }
    }

    &:not(:disabled):not(.disabled):active {
      border-color: transparent;
      &:focus {
        box-shadow: none;
      }
    }

    &:active,
    &:focus:active {
      box-shadow: none;
    }
  }
}
