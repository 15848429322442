@import './src/frontend_common/Theme.scss';

.inbox_desktop_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  margin-top: $containerMarginTop;
  .inbox_list {
    padding: 0px;
    height: 800px;
    width: 38.5%;
    box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
    .inbox_list_title {
      width: 100%;
      height: 81px;
    }
    .inbox_list_separateLine {
      width: 100%;
      height: 1px;
      background-color: $greyG5;
    }
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: $transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: $primaryP2;
      border-radius: 2px;
    }
    .inbox_list_listArea {
      width: 100%;
      height: 718px;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: $greyG8;
      border-radius: 0 0 8px 8px;
      .nothing-area {
        margin-top: 260px;
      }
      .inbox_list_loadmoreArea {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
