@import './src/frontend_common/Theme.scss';

.activity-log-mobile-page-container {
  .activity-log-table-view {
    margin-top: 24px;

    .reward_status_table_head {
      justify-content: space-between;

      .reward_status_table_head_item:nth-child(2) {
        text-align: end;
      }
    }

    .activity-log-table-content
      .activity-log-table.table
      > tbody
      > tr:not(.no-record-tr) {
      justify-content: space-between;
    }
  }
}
