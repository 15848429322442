.campagin_card_container {
    .campagin_card_masonry_grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -2.5%; /* gutter size offset */
        width: auto;
        margin-top: 0px;
    }
    .campagin_card_masonry_grid_column {
        padding-left: 2.5%; /* gutter size */
        background-clip: padding-box;

        .campagin_card:nth-child(1) {
            margin: 0px;
        }
    }        
}

.campagin_card_col {
    padding-top: 15px;
    padding-bottom: 15px;
}

.campagin_spinner_row {
    height: 100px;
}

@media(min-width:768px) {
    .campagin_card_container .campagin_card_masonry_grid {
        margin-top: 24px;
    }
}