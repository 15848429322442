@import "../../frontend_common/Theme.scss";

.home_banner_container {
  max-width: 1140px;
  margin: 0 auto;
}
#home_banner_slider {
  .carousel-indicators {
    bottom: -31px;
    > li:not(.active){
      background-color: $greyG5;
      width: 6px;
      height: 6px;
    }
    > li.active {
      height: 6px;
    }
  }
  .detail_cauousel_prev_icon,
  .detail_carousel_next_icon {
    filter: $greyG8Filter;
  }
  .detail_carousel_next_icon {
    display: var(--carouselNextControlShow, none);
  }
  .detail_cauousel_prev_icon {
    display: var(--carouselPrevControlShow, none);
  }
  .carousel-control-prev {
    pointer-events: var(--carouselPrevButtonEvent, none);
  }
  .carousel-control-next {
    pointer-events: var(--carouselNextButtonEvent, none);
  }
}

@media (max-width:767px)  {
  #home_banner_slider {
    .detail_cauousel_prev_icon,
    .detail_carousel_next_icon {
      display: none;
    }
  }
}
