@import "../../frontend_common/Theme.scss";

.home-current-points-section {
  display: flex;
  flex-direction: column;
  background-color: $greyG8;
  border-radius: 8px;
  width: 256px;
  height: 216px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  
  .common-profile-section-text {
    gap: 4px;
    .current-point-text {
      text-align: center;
      color: $primaryP1;
      
    }
  }
}
.point-icon,.point-icon > img {
  width: 56px;
  height: 56px;
  filter: $primaryP2Filter;
}
