@import './src/frontend_common/Theme.scss';

.user-section-container {
  .gap-4 {
    gap: 32px;
  }
  .loading-jumping-balls {
    height: 32px;
    padding: 0;
    > svg path {
      fill: $greyG8;
    }
  }
}
