@import "../../frontend_common/Theme.scss";

.home-level-benfient-section,
.home-level-rule-section {
  flex: 1;
  background-color: $greyG8;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  height: 86px;
  padding: 0 24px;
  cursor: pointer;

  .arrow-icon,
  .arrow-icon>img {
    width: 24px;
    height: 24px;
    filter: $primaryP2Filter;
  }

  .common-profile-section-text {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.common-profile-section-text {
  color: $greyG2;
}

.common-profile-section-text>img {
  width: 24px;
  height: 24px;
  filter: $primaryP2Filter;
}

.level-benefit-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.single-benefit-section {
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;

  .level-benefit-icon {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    height: 100%;
    width: 16px;

    >div:first-child {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $greyG5;
    }

    >div:last-child {
      position: absolute;
      background-color: $greyG5;
      width: 2px;
      top: 24px;
      height: calc(100% - 24px);
    }
  }

  .level-benefit-icon.active {
    >div {
      background-color: $primaryP2;
    }
  }

  .single-benefit-title {
    color: $primaryP1;
  }

  .single-benefit-content {
    color: $greyG3;
  }

}


@media (max-width: 320px) {
  .home-level-benfient-section,
  .home-level-rule-section {
    padding: 0px;
    justify-content: center;
  }
}