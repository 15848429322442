@import "../../frontend_common/Theme.scss";

.share_logo {
  width: 45px;
  height: 45px;
  object-fit: contain;
  margin-right: 11px;
  border-radius: 100%;

  &.facebook_logo {
    background-color: #2E89FF;
  }
  &.email_logo {
    background-color: $secondaryS4;
  }
}

.shared_container {
  margin-bottom: 25px;

  .share-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  
    padding: 13px 24px;
    border-radius: 100px;
    border: solid 2px $primaryP2;
    background-color: $greyG8;
    outline: none;

    .share-image {
      width: 24px;
      height: 24px;
      filter: $primaryP2Filter;
    }

    .share-label {
      margin: 0 0 0 4px;
      color: $primaryP2;

      cursor: pointer;
    }
  }
}

@media (max-width: 1023px) {
  .shared_container {
    .share-button {
      padding: 6px 24px;
    }
  }
}