@import "./src/frontend_common/Theme.scss";

.check_in_section {
  width: 100%;
  background-color: $greyG8;
  padding: 24px 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .row {
    padding-left: 16px;
  }
}

.point_earned {
  text-align: left;
  color: $primaryP1;
}

.points {
  color: $secondaryS2;
  margin-top: 8px;
}

.check_in_button_section {
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
}
.remind_message {
  justify-content: start;
}


.check_in_button {
  @if $enableCustomBtnStyle == 1 {
    background: $secondaryButtonBackground;
    color: $secondaryButtonColor;
    &:disabled {
      opacity: 0.6;
    }
  } @else {
    background-color: $secondaryS2 ;
    &:disabled {
      background-color: $greyG4;
    }
  }
  width: 100%;
  height: 50px;
  border-radius: 30px;
  border: 0;
  &:disabled {
    color: $greyG8;
  }
  &:hover[disabled] {
    cursor: auto;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline:0;
  }
}
.tick_animation_btn,
.loading_animation_btn {
  @if $enableCustomBtnStyle == 1 {
    background: $secondaryButtonBackground;
  } @else {
    background: $greyG8;
  }
  border: none;
  
  > div {
    height: 100%;
  }
  & svg path {
    fill: $greyG8;
  }
}
.loading_animation_btn {
  @if $enableCustomBtnStyle != 1 {
    background: $secondaryS2;
  }
}
.tick_animation_btn {
  background:$greyG8;
  & svg path {
    fill: $secondaryS2;
  }
}

.check_in_label {
  text-align: center;
  height: 24px;
  color: $greyG8;
  margin: auto 0;
  &:hover {
    cursor: inherit;
  }
}

.campaign_avaliable_message {
  text-align: left;
  color: $greyG1;
  margin-bottom: 0px;

  &.download_text {
    color: $primaryP2;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
