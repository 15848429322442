@import "../frontend_common/Theme.scss";

.transaction_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  margin-top: $containerMarginTop;
  .transaction_list {
    padding: 0px;
    height: 800px;
    width: 38.5%;
    background-color: $greyG8;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
    .transaction_list_title {
      display: flex;
      align-items: center;
      padding-left: 15px;
      width: 100%;
      height: 81px;
      color: $greyG1;
    }
    .transaction_list_separateLine {
      width: 100%;
      height: 1px;
      background-color: $greyG5;
    }
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: $transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: $primaryP2;
      border-radius: 2px;
    }
    .transaction_list_listArea {
      width: 100%;
      // height: 718px;
      height: 646px;
      // overflow: auto;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: $greyG8;
      border-radius: 0 0 8px 8px;
      .transaction_list_loadmoreArea {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    .transaction_list_listArea.with-nothing-area{
      // height: 364px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .transaction_list_button {
      width: 100%;
      height: 72px;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: $greyG8;
      box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.10);
      .btn_upload_receipt {
        @if $enableCustomBtnStyle == 1 {
          background: $primaryButtonBackground;
          color: $primaryButtonColor;
        } @else {
          background: $primaryP2;
          color: $greyG8;
        }
        display: flex;
        padding: 13px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;

        border-radius: 100px;
        border-color: $primaryP2;
        outline: none;
        border: none;

        text-align: center;

        &:active, &:focus, &:focus-visible, &:active:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .transaction_container {
    .transaction_list {
      width: 100%;
      height: auto;
      .transaction_list_listArea {
        height: auto;
      }
      .transaction_list_listArea.with-nothing-area{
        height: 364px;
      }
      .transaction_list_button {
        position: sticky;
        bottom: 0;
      }
    }
    .transaction_content {
      display: none;
    }
  }
  .transaction_list.offer_floatButton_container {
    bottom: 10.5%;
  }
}
