@import "../../frontend_common/Theme.scss";

div.offer_nav_container {
  min-height: 48px;
  padding: 10px 16px;

  .offer_web_nav_row {
    margin: 0;
    width: 100%;
    flex-wrap: nowrap;
    align-items: flex-start;
    .offer_web_nav_left_arrow,
    .offer_web_nav_right_arrow {
      cursor: pointer;
      width: 24px;
      height: 24px;
      filter: $primaryP2Filter;
    }
    .offer_web_nav_left_arrow {
      margin-right: 24px;
      transform: rotate(180deg);
    }
    .offer_web_nav_right_arrow {
      margin-left: 24px;
    }
  }
}

.offer_nav_bar {
  flex-wrap: nowrap !important;
  overflow-x: visible;
  overflow-y: hidden;
  gap: 24px;

  //   padding-bottom: 10px;
  //   scrollbar-width: none;
}

.offer_nav_bar::-webkit-scrollbar {
  display: none;
}

.offer_nav_item {
  text-align: center;
  color: $greyG4;
  padding-bottom: 4px;

  cursor: pointer;
  white-space: nowrap;
}

.offer_nav_item:hover {
  color: $primaryP1;
}

.offer_nav_item.active {
  color: $primaryP1;
  border-bottom: 2px solid $primaryP1;
}

.offer_nav_bar .nav-item {
  display: inline-block;
}

// div.offer_breadcrumbs_container {
//   padding-top: 11px;
//   padding-left: 15px;
// }

// .offer_breadcrumbs_item {
//   height: 19px;
//   cursor: pointer;
// }

// .offer_breadcrumbs_divider {
//   height: 19px;
//   padding-left: 1px;
//   padding-right: 1px;
//   cursor: default;
// }
div.offer-nav-dropown {
  padding: 16px 15px;
}
