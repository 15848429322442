@import "../../frontend_common/Theme.scss";

.home-profile-custom-modal {
  > div {
    justify-content: center;
  }

  .modal-content,
  .home-profile-custom-body {
    width: 624px;
    background-color: $greyG8;
    border: none;
    border-radius: 8px;
  }

  .home-profile-custom-body {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }


  .home-profile-custom-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-icon,
    .close-icon>img {
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
    .close-icon {
      background-color: $opacityOP1;
      border-radius: 24px;
      > img {
        filter: $greyG8Filter;
      }
    }
  }

  .tab-pane {
    padding: 0;
  }

  .reward-status-table-body {
    overflow-y: scroll;
    background-color: $greyG7;

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    // &::-webkit-scrollbar-track {
      // background: $greyG7;
    // }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $primaryP2;
      border-radius: 2px;
    }
  }
}

.home-profile-custom-tab {
  gap: 24px;

  .nav-item>a {
    margin: 10px 0;
    padding: 0 0 4px 0;
    color: $greyG4;

    &.active {
      color: $primaryP1;
      border-bottom: 2px solid $primaryP1;
    }
  }
}