@import '../../frontend_common/Theme.scss';

.level-rule-modal {

    .modal-dialog {
        max-width: calc(100vw * 0.61);
    }
    .modal-content {
        padding: 32px 24px;
        gap: 24px;
    }

    .modal-header {
        border-bottom: 0px;
        align-items: center;
        padding: 0;
        width: 100%;

        .level-rule-aillia-title {
            display: none;
        }

        .level-rule-close-btn {
            background-color: $opacityOP1;
            border: 0px;
            padding: 0px;
            border-radius: 24px;

            &:focus {
                outline: 0px;
            }

            >img {
                filter: $greyG8Filter;
            }
        }

        .level-rule-title {
            color: $greyG1;
        }
    }

    .modal-body {
        padding: 0;
        
        display: flex;
        flex-direction: column;
        gap: 24px;

        .level-rule-second-title {
            color: $primaryP1;
            margin-bottom: 8px;
        }

        .level-rule-detail-message {
            color: $greyG3;
            > p {
                margin: 0;
            }
        }

        .level-rule-table {
            width: 100%;
            margin: 20px auto 0px;

            table {
                thead {
                    background-color: $greyG4;
                }

                th,
                td {
                    border: 1px solid $greyG4;
                    color: $greyG3;
                    text-align: center;
                }
                th {
                    color: $greyG1;
                }
                .level-name {
                    text-transform:uppercase
                }
            }
        }
    }
}

@media (max-width: 1023.98px) {
    .level-rule-modal {
        .modal-dialog {
            max-width: 100%;
            margin: 0px;
        } 
        .modal-content {
            border: 0px;
            border-radius: 0px;
        }
        .modal-header {
            display: block;
            padding: 10px 6px 0px 12px;
            .level-rule-aillia-title {
                display: flex;
                justify-content: space-between;
            }
            .level-rule-aillia-close-btn {
                border: 0px;
                background-color: $greyG8;
                padding: 0px;
                &:focus {
                    outline: 0px;
                }
            }
            .level-rule-close-btn {
                display: none;
            }
        }
        .modal-body {
            padding: 17px 0px 2px;

            .level-rule-item-section {
                padding: 0px 12px 20px;
                margin-bottom: 22px;
                border-bottom: 6px solid $greyG7;

                &.last-section {
                    border-bottom: 0px;
                }
            }

            .level-rule-table {
                width: calc(100vw * 0.696);
            }
        }
    }
}