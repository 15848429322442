@import "../../frontend_common/Theme.scss";
.custom-nav-dropdown-container {
  display: none;
}
div.customer-nav-dropdown {
  border-radius: 8px;
  border: 1px solid $greyG4;
  width: 100%;
  max-height: 48px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: $greyG8;
  position: relative;

  &:focus {
    outline: 0px;
  }

  > div {
    width: 100%;
    height: 48px;
    align-items: center;

    &:focus {
      outline: 0px;
    }

    & > a.dropdown-toggle {
      width: 100%;
      color: $greyG1;
      padding: 12px 16px;
      &::after {
        border: 0;
      }
      &:focus {
        outline: 0px;
      }
    }

    & > div.dropdown-menu {
      background-color: $greyG8;
      width: 100%;
      padding: 0px;
      border: 0px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      max-height: 6 * 46px + 5px;
      overflow-y: scroll;
      z-index: 901;

      > a.dropdown-item {
        padding: 12px 16px;

        &:not(:last-child) {
          border-bottom: 1px solid $greyG6;
        }
        &:last-child {
          border-radius: 0 0 8px 8px;
        }
        &:first-child {
          border-radius: 8px 8px 0 0;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: $greyG8;
          outline: 0;
          color: $greyG1;
        }
      }
    }
  }
  
  > img {
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
    filter: $greyG4Filter;
  }
}

.custom-nav-dropdown-arrow-img {
  position: absolute;
  right: 0;
}

@media (max-width: 767px) {
  .custom-nav-dropdown-container {
    display: flex;
    padding: 16px 0;
    background-color: $greyG7;

    &.dropdown-nav-sticky {
      position: sticky;
      z-index: 901;
    }
  }
  div.user-section-container {
    margin-top: 0;
  }
}
