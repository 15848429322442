@import './src/frontend_common/Theme.scss';

.transaction-history-table-view {
  .reward_status_table_head {
    justify-content: space-between;

    .reward_status_table_head_item {
      &.invoice_id {
        width: calc(100% * 0.48);
      }

      &.ordered {
        min-width: 100px;
        width: calc(100% * 0.28);
      }

      &.long_invoice_id {
        width: calc(100% * 0.7);
      }

      &.total {
        min-width: 100px;
        width: calc(100% * 0.16);
        text-align: end;
      }
    }
  }
}

.home-profile-custom-body.transaction_detail_popup {
  background-color: $greyG6;
  &.home-profile-custom-body {
    gap: 16px;
  }
}

.transaction_detail_scroll_view {
  overflow-y: scroll;
  max-height: 410px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  // &::-webkit-scrollbar-track {
  // background: $greyG7;
  // }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primaryP2;
    border-radius: 2px;
  }
}

.detail_right_arrow {
  &:hover {
    cursor: pointer;
  }
}

.transaction_detail_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .title_back_icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: $primaryP2Filter;
    &:hover {
      cursor: pointer;
    }
    .img {
      width: 24px;
      height: 24px;
    }
  }
  .title {
    color: $greyG1;
  }
}

.transaction-history-table-content {
  .activity-log-table.table {
    > tbody > tr:not(.no-record-tr) {
      justify-content: space-between;
      align-items: center;
    }

    .item_name {
      width: calc(100% * 0.48);

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      img.right-arrow {
        width: 16px;
        height: 16px;
        min-width: 16px;
        filter: $primaryP2Filter;
      }
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .item-title {
        color: $primaryP2;
      }
      .item-sub-title {
        color: $greyG2;
      }
    }

    .item_date {
      min-width: 134px;
      width: calc(100% * 0.28);
    }

    .item-content {
      color: $primaryP2;
      min-width: 95px;
      text-align: end;
      width: calc(100% * 0.16);
      padding: 0;
      font-size: 15px;
    }
  }

  tr.no-record-tr {
    justify-content: center;
  }
}

@media (max-width: 1023.98px) {
  .transaction-history-table-content {
    .activity-log-table.table .item_name {
      width: auto;
    }
  }
}
