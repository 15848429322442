@import "../../frontend_common/Theme.scss";

.stamp-wrap {
    width: 300px;
    padding-bottom: 24px;
}

.stamp-card-container {

}

.stamp-card-title {
    display: block;
    color: $primaryP1;
}

.badge-container {
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: $greyG8;
}

.badge-row-container {
    display: flex;
    flex-direction: row;
}

.badge-row-reverse {
    flex-direction: row-reverse;
}

.badge-card-container {
    display: flex;
}

.badge-card-container-left {

}

.badge-card-container-right {
    flex-direction: row-reverse;
}

.badge-card-container-bottom {
    flex-direction: column;
    align-items: center;
}

.badge-card-button {
    display: flex;
    align-items: center;
    flex-flow: column;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
}

.badge-card-button:focus {
    outline: none;
}

.badge-card-icon-container {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 8px;
    background-color: $greyG6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.badge-card-icon {
    width: 36px;
    height: 36px;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: contain;
}

.badge-card-acquire-container {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 18px;
    background-color: $secondaryS4;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.badge-card-acquire-container.acquired {
    background-color: $greyG3;
}

.badge-card-acquire-text {
    color: $greyG8;
}

.badge-card-index {
    margin-top: 4px;
    color: $primaryP1;
}

.badge-card-line {
    background-color: $greyG5;
}

.badge-card-line-left,
.badge-card-line-right {
    margin-top: 29px;
    height: 6px;
    width: 38px;
}

.badge-card-line-bottom {
    height: 20px;
    width: 6px;
}

.task-card-title {
    display: block;
    margin-top: 24px;
    color: $primaryP1;
}

.task-wrap {
    display: flex;
    flex-direction: column;
}

.task-card-container {
    margin-top: 16px;
    padding: 16px;
    width: 300px;
    height: auto;
    border-radius: 8px;
    background-color: $greyG8;
}

.task-card-top-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.task-card-icon {
    width: 48px;
    height: 48px;
}

.task-card-name {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 8px;
    color: $greyG1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.task-card-bottom-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
}

.task-card-reward-number {
    margin-left: auto;
    color: $primaryP2;
}

.task-card-reward-icon {
    margin-left: 2px;
    margin-right: 8px;
    width: 20px;
    height: 20px;

    svg {
        vertical-align: unset;
    }
}

.task-card-reward-number-extra {
    margin-left: -6px;
    margin-right: 8px;
    color: $primaryP2;
}

.task-card-button {
    min-height: 32px;
    width: 150px !important;
    padding: 0 10px !important;
    @if $enableCustomBtnStyle == 1 {
      background: $secondaryButtonBackground;
      &:disabled {
        opacity: 0.6;
      }
    } @else {
      background: $secondaryS2;
      &:disabled {
        background: $greyG4;
      }
    }
}

.stamp-campaign-loading-jumping-balls {
    height: 32px;
    padding: 0;
    > svg path{
        fill: $greyG8;
    }
}

.task-card-button-tick {
    background-color: $greyG8;
}

.stamp-campaign-tick-green {
    height: 32px;
    padding: 0;
    > svg path{
        fill: $secondaryS2;
    }
}


.task-card-button-text {
    @if $enableCustomBtnStyle == 1 {
        color: $secondaryButtonColor;
    } @else {
        color: $greyG8;
    }
}

.stamp-section-left {
    display: none;
}

@media (max-width: 1023px) {
    .stamp-section-left {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
        margin-top: 24px;
        padding-top: 24px;
        padding-left: 15px;
        padding-right: 15px;
        background-color: $greyG6;
    }

    .stamp-wrap {
        width: 100%;
    }

    .badge-row-container {
        width: 100%;
    }

    .badge-card-container {
        width: 15vw;
    }

    .badge-card-container-left,
    .badge-card-container-right {
        width: calc((100% - 60vw) / 3 + 15vw)
    }

    .badge-card-icon-container {
        width: 15vw;
        height: 15vw;
    }

    .badge-card-icon {
        height: 10vw;
        width: 10vw;
    }

    .badge-card-acquire-container {
        width: 15vw;
    }

    .badge-card-line-left,
    .badge-card-line-right {
        margin-top: 7vw;
        height: 1vw;
        width: calc(100% - 15vw);
    }

    .badge-card-line-bottom {
        height: 3vw;
        width: 1vw;
    }

    .task-card-container {
        width: 100%;
    }
}