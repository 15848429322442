@import "../../frontend_common/Theme.scss";

.profile-no-level-section {
  .level-section-content {
    border-radius: 8px;
    background-color: $greyG8;
    height: 106px;

    justify-content: center;

    .level-section-header {
      align-items: center;
    }
  }
}

div.my-profile-content-section.mobile-view .level-and-point .profile-no-level-section {
  .level-section-content {
    height: 64px;
  }
}