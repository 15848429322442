@import './src/frontend_common/Theme.scss';

.store_list_filter {
  display: flex;
  margin-top: 24px;
  height: 48px;
  width: 100%;
  .store_list_filter_container {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0px;
    .store_filter {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      width: 400px;
      height: 48px;
      border-radius: 8px;
      background-color: $greyG8;
      .filter_dropdown_button {
        padding: 0 16px;
        display: flex;
        flex: 1;
        width: auto;
        height: 48px;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.show_dot:after {
          position: relative;
          top: -5px;
          left: 5px;
          content: '\A';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $primaryP2;
          display: inline-block;
        }
        span {
          color: $greyG2;

          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
        .chevron {
          margin-left: 8px;
          width: 12px;
          height: 12px;
        }
      }
      .custom_separate.filter_dropdown_separate {
        width: 1px;
        height: 100%;
        &:after {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }
    .filter_dropdown_content {
      position: relative;
      inset: 0px auto auto 0px;
      margin: 0px;
      transform: translate3d(0px, 8px, 0px);
    }
  }
}

.store_list_container {
  margin-top: 24px;
  .nothing-area {
    margin-top: 48px;
  }
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    margin-right: -36px; /* gutter size offset */
    width: -webkit-fill-available;
  }
}

.custom-nav-dropdown-container.about-page-nav-dropdown {
  margin: 0 -15px;
  padding: 16px 15px;
}
@media (max-width: 767px) {
  .user_container.container {
    .store_list_filter {
      position: sticky;
      z-index: 900;
      top: calc(var(--page-nav-height) + var(--app-banner-height));
      margin-top: 0;
      margin-right: -15px;
      margin-left: -15px;
      width: auto;
      .store_list_filter_container {
        .filter_bottom_border.custom_separate {
          width: 100%;
          height: 1px;
          background-color: $greyG8;
          &:after {
            margin-left: 16px;
            margin-right: 16px;
            height: 1px;
            width: 100%;
          }
        }
        .store_filter {
          width: 100%;
          border-radius: 0;
        }
        .filter_dropdown_content {
          width: 100%;
          transform: translate3d(0px, 1px, 0px);
          &.card {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }
    }
    .store_list_container {
      margin-top: 1px;
      margin-right: -15px;
      margin-left: -15px;
      width: auto;
      .my-masonry-grid {
        margin-left: -15px; /* gutter size offset */
        margin-right: -15px; /* gutter size offset */
      }
      .store_card {
        border-radius: 0px;
      }
    }
  }
}

@media (max-width: 400px) {
}
