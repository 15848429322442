@import "./src/frontend_common/Theme.scss";

.coupon_card {
  &:hover {
    box-shadow: 0 2px 18px 0 rgba(169, 169, 169, 0.5);
    cursor: pointer;
  }
}
.coupon_list_page_container {
  position: relative;
  padding-top: 9px;
}

div.coupon_list_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  padding-left: 27px;
  padding-right: 6px;
  //   margin-top: -20px;

  > div.tab-pane {
    width: 100%;
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -19px; /* gutter size offset */
    width: -webkit-fill-available;
  }
  .my-masonry-grid_column {
    // padding-left: 21px; /* gutter size */
    background-clip: padding-box;
    width: 100%;
  }
  .nothing-area {
    margin-top: 184px;
  }
}

div.coupon_list_tab_bar {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(38, 38, 38, 0.5);
  background-color: $greyG2;
  max-width: 485px;
  min-height: 54px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  position: sticky;
  top: 85%;
  bottom: 4%;
  z-index: 10;
  margin-bottom: 10px;

  > div > a {
    color: $greyG4;
    padding: 12px 8px;
    &.active {
      color: $greyG7;
    }
  }
}
div.dropdown_nav {
  display: none;
}

.coupon_spinner_row {
  position: absolute;
  left: 0;
  right: 0;
  // bottom: 15px;
  // margin-top: 20px;
  // margin-bottom: 20px;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-right {
  justify-content: right;
}
@media (max-width: 1023px) {
  div.coupon_list_tab_bar {
    position: sticky;
    bottom: 10px;
    left: calc((100% - 296px) / 2);
    max-width: 302px;
    margin: 0;
    > div > a {
      text-align: left;
    }
  }
  div.dropdown_nav {
    display: flex;
    margin-bottom: 17px;
  }
  div.coupon_list_container {
    .my-masonry-grid_column {
      padding-left: 8px;
    }
  }
  div.coupon_list_container {
    padding-left: 15px;
    margin-top: 0;
  }
}

@media (max-width: 474px) {
  div.coupon_list_tab_bar {
    left: auto;
    max-width: none;
  }
}
