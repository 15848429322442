@import '../../frontend_common/Theme.scss';
.detail_container {
    background-color: $greyG7;
    padding-top: 11px;
}

.back_btn {
    width: 45px;
    height: 45px;
    background-color: $greyG8;
    border: 0;
    margin-bottom: 6px;
    padding: 0;
    &:focus, &:active {
        background-color: $greyG8;
        border: 0;
        outline: 0px;
    }
}

div.detail_row {
    margin-left: 0px;
    margin-right: 0px;
    background-color: $greyG7;
}