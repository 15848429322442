div.my-profile-content-section {
  margin-top: 24px;
  display: flex;
  width: 100%;
  margin-left: 0px;
  gap: 24px;

  .my-profile-content-right-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 216px;
    &.no-level {
      height: 106px;
    }
  }
  .level-info-area {
    display: flex;
    flex: 1 1;
    width: 100%;
    gap: 24px;
  }

  &.col {
    height: 216px;
    padding: 0;
  }

  .level-section {
    margin-right: 26px;
    max-width: 44%;
  }

  .current-item-section:not(:last-child) {
    margin-right: 17.5px;
  }

  .current-item-section {
    padding: 9px;
  }

  // .current-item-section + .current-item-section {
  //   margin-left: 17.5px;
  // }

  .current-item-section-no-level {
    padding: 41px 0px;
  }

  .no-level-area {
    display: flex;
    justify-content: center;
    // align-items: center;

    width: 100%;
    height: 100%;
  }

  .no-level-title-area {
    // margin-left: auto;
    // margin-right: auto;
    // width: 33%;
    margin-top: 13%;
  }

  .no-level-title {
  }

  .no-level-overview-img {
    position: absolute;
    bottom: 0;
    width: 100%;
    // border: 1px solid blue;
  }
}

div.my-profile-content-section.mobile-view {
  display: flex;
  flex-direction: column;
  margin-top: 0;

  .level-and-point {
    display: flex;
    flex-direction: column;
    gap: 12px;

    >div {
      margin: 0;
      width: 100%;
    }

    .level-section-content {
      width: auto;
      height: 216px;
    }

    .level-bg {
      width: 100%;
      height: 216px;
    }

    .level-section-content,
    .home-retain-membership-section,
    .home-current-points-section {
      padding: 12px;
    }

    .level-rule-and-benefit {
      display: flex;
      gap: 12px;

      >div {
        margin: 0;
        width: calc((100% - 12px) / 2);
        height: 72px;
      }


    }

    .home-current-points-section {
      flex-direction: row;
      justify-content: flex-start;
      height: auto;

      .common-profile-section-text {
        align-items: flex-start;
      }
    }
  }

  .reward_status_table_head {
    border-radius: 8px 8px 0 0;
    padding: 12px;

    .reward_status_table_head_item:nth-child(2) {
      text-align: end;
    }
  }
  .reward-status-table-body {
    .reward-status-table >tbody>tr {
      padding: 16px 12px;
    }

    .reward-status-table >tbody>tr.tr-stamp {
      padding: 11px 12px 8px 12px;

      .item_name.stamp-status {
        max-width: 65%;
        width: 65%;
      }
    }
  }
}

.user-section-container:has(.my-profile-content-section) {
  padding: 0;
}

.level-benfient-and-expriy-date-row {
  margin-top: 22px;
}

.level-benfient-section,
.level-expiry-section {
  flex-grow: 1;
}

.level-benfient-section {
  margin-right: 18px;
}

// .level-benfient-section + .level-expiry-section {
//   margin-left: 18px;
// }

@media (max-width: 1023.98px) {
  .level-section {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .level-benfient-section,
  .level-expiry-section {
    margin-top: 4px;
  }
}