@import "../frontend_common/Theme.scss";

div.campaign_detail_container {
  background-color: $greyG7;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 60px;
  padding-left: 45px;
}

div.left_section {
  background-color: $greyG8;
  width: 62%;
}
.background_color_g6 {
  background-color: $greyG6;
}

.offer_tag,
.featured_tag {
  max-width: 199px;
  max-height: 30px;
  background-color: $primaryP2;
  margin-top: 24px;
  margin-bottom: 0;
  // margin-left: 5px;
  padding: 2px 8px;
}
.featured_tag {
  margin-top: 24px;
  // margin-bottom: 24px;
  margin-left: 9px;
}

.out_of_stock_tag {
  background-color: $secondaryS4;
  margin-left: 8px;
}

.offer_tag .offer_state,
.feature {
  color: $greyG8;
  align-self: center;
}

.offer_title {
  color: $primaryP1;
  margin: 20px;
}

.expiry_date {
  color: $greyG3;
  margin-left: 9px;
  margin-bottom: 24px;
}

.short_description {
  background-color: $greyG6;
  // margin: 4px 20px 20px 20px;
  padding: 24px;
  color: $greyG3;
  width: 100%;
  white-space: pre-line;
}
.short_description p {
  margin-bottom: 0px;
}

.brand_image {
  width: 59px;
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.brand_name {
  line-height: 1;
  color: $primaryP1;
  margin-top: 0px;
  margin-bottom: 0px;
  align-self: center;
}

div.right_section {
  background-color: $greyG7;
  width: 30%;
  padding:0;
}

.media {
  margin-bottom: 8px;
  margin-top: 12px;
}

div.instruction_container {
  margin: 0 20px;
  padding: 0px;
  margin-top: 12px;
  width: 100%;
}

.instruction_sections,
.details {
  max-width: 100%;
  min-height: 24px;
  color: $primaryP1;
  // margin-left: 5px;
  // margin-top: 24px;
  margin-bottom: 16px;

  > p {
    word-break: break-word;
    > a {
      color: $primaryP2;
      text-decoration: none;
    }
  }

}

.instruction_step,
.detail_description {
  width: 100%;
  color: $greyG3;
  margin-left: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-right: 5px;
  white-space: pre-line;
  > p {
    word-break: break-word;

    > a {
      color: $primaryP2;
      text-decoration: none;
    }
  } 
}

.read-more {
  color: $primaryP2;
  display: block;
  // position: relative;
  margin-left: 5px;
  margin-bottom: 24px;
}

.instruction_step ol {
  padding-left: 20px;
}

div.share_block {
  justify-content: center;
  display: none;
}

.media-body {
  align-self: center;
}

button.down_arrow_btn,
button.up_arrow_btn,
button.down_arrow_btn:hover button.down_arrow_btn:focus {
  width: 45px;
  height: 45px;
  object-fit: contain;
  background-color: initial;
  border: 0px;
  padding: 0;
  align-self: center;
  background-image: url("/campaignDetail/downBtn/btn-down-arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  filter: $primaryP2Filter;
  &:focus,
  &:hover {
    outline: 0px;
  }
  margin-left: auto;
  margin-right: -9px;
}
button.up_arrow_btn {
  background-image: url("/campaignDetail/upBtn/btn-down-arrow.png");
}

.store_collapse_section {
  padding: 16px 10px 0px;
  color: $greyG1;
  > p {
    word-break:break-all;

    > a {
      color: $primaryP2;
      text-decoration: none;
    }
  }
}
.store_section {
  margin-right: 0px;
}

.media_body {
  display: contents;
  margin-left: 13px;
}

div.share_block div.container .row {
  justify-content: center;
}

div.brand_container {
  padding-left: 5px;
  margin-top: 24px;
  // margin-bottom: 24px;
}

.dividing {
  height: 8px;
  background-color: $greyG6;
}

.row .campaign_dividing_line{
  border: 1px solid $greyG5;
  margin: 8px 10px 14px;
  // margin-right: 1px;
  width: calc(100% - 18px);
}

div.more_or_detail_container {
  position: relative;
  margin: 0 16px;
  padding: 0px;
  margin-top: 12px;
}

.float_right_section {
  position: sticky;
  top: 40px;
  background-color: $greyG7;
  padding: 0 16px;
  
  .pick_up_section {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
  }

  &:has(.scanner-modal) {
    position: static;
  }
}

div.open_app_section {
  position: -webkit-sticky;
  position: sticky;
  justify-content: center;
  bottom: 15px;
  display: flex;
  z-index: 20;
  &.coupon_type {
    bottom: 90px;
  }
  &.earn_type {
    bottom: 116px;
  }
}
.open_app_btn {
  padding: 6px 17px;
  border-radius: 20px;
  border: solid 2px $primaryP2;
  max-height: 40px;
  align-self: center;
  background-color: $primaryP2;
  color: $greyG8;
  box-shadow: 0 5px 8px 0 rgba(50, 93, 143, 0.3);
  &:focus {
    outline: 0;
  }
}

div.mobile_bottom_bar {
  position: sticky ;
  display: none;
  &:has(.scanner-modal) {
    position: static;
  }
}
@media (max-width: 1024px) {
  div.campaign_detail_container {
    max-width: 100%;
    background-color: $greyG8;
  }
}

@media (max-width: 1023px) {
  div.right_section {
    display: none;
  }
  div.brand_container,
  div.nav_row,
  .offer_tag,
  .media {
    margin-left: 0px;
  }
  .out_of_stock_tag {
    margin-left: 8px;
  }
  // .expiry_date {
    // margin-left: 5px;
  // }
  .offer_title {
    margin-left: 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-bottom: 0px;
  }
  .brand_name {
  }
  div.campaign_detail_container {
    padding-bottom: 0px;
    padding-left: 15px;
  }
  div.share_block {
    display: block;
    margin-top: 50px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  div.fixed-bottom {
    display: block;
    z-index: 998;
  }
  div.instruction_container {
    position: relative;
    margin: 12px 15px 0px;
  }
  .short_description {
    padding: 24px 16px;
  }
  div.top_dividing {
    height: 1px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: $greyG5;
  }
  div.mobile_bottom_bar {
    display: flex;
  }
  div.nav_row a {
    color: $greyG3 !important;
    text-decoration: none;
    margin: 0 8px !important;
  }
  div.nav_row a:hover {
    color: $primaryP2;
  }
}
@media (max-width: 1023px) {
  .featured_tag {
    margin-top: 12px;
    // margin-bottom: 12px;
    margin-left: 1px;
  }

  .offer_tag .offer_state,
  .feature {
  }

  .offer_title {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    margin-top: 12px;
  }
  .coupon_title {
    margin-left: 33px;
    max-width: calc(100% - 64px);
    word-wrap: break-word;
  }

  .expiry_date {
    margin-left: 1px;
  }

  .short_description {
  }

  .instruction_sections,
  .details {
  }

  .instruction_step,
  .detail_description {
  }

  .instruction_step_masked {
    max-height: 85px;
    overflow: hidden;
  }

  .detail_description_masked {
    max-height: 198px;
    overflow: hidden;

  }

  .mask{
    display: block;
    position: absolute;
    bottom: 61px;
    width: 100%;
    height: 30%;
    background: linear-gradient(to bottom, rgba(255,0,0,0), $greyG8);
  }

  .store_collapse_section {
  }

  .row .campaign_dividing_line{
    margin-left: 1px;
  }
}