@import './src/frontend_common/Theme.scss';

.about_us_nav_desktop {
  width: 100%;
  height: 48px;
  background-color: $greyG8;
}

.about_us_nav {
  padding: 4px 10px;
  flex-wrap: nowrap !important;
  > div.nav-item {
    background-color: initial;
    margin-left: 24px;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:hover {
      .nav-link {
        color: $primaryP1;
      }
    }
    .nav-link {
      padding-left: 0px;
      padding-right: 0px;
      color: $greyG4;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        color: $primaryP1;
        border-bottom: 2px solid $primaryP1;
        cursor: default;
        pointer-events: none;
      }
    }
    a:hover {
      text-decoration: none;
    }
    a:active {
      text-decoration: none;
    }
  }
}

@media (max-width: 767px) {
  .about_us_nav_desktop {
    display: none;
  }
  div.about-page-nav-dropdown {
    display: flex;
  }
}
