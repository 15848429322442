@import "../frontend_common/Theme.scss";

.create_transaction_breadcrumbs {
  padding: 0px;
  height: 45px;
  padding-top: 12px;
  padding-left: 15px;

  .d-md-none {
    display: none!important;
  }
  @media (min-width: 767px){
    .d-md-none {
        display: flex!important;
    }
  }
}

.create_transaction_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 50px;
  .create_transaction_content {
    display: block;
    padding: 0px;
    width: 100%;
    background-color: $greyG8;
    border-radius: 8px;
    box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
    padding: 4px 24px 24px;
    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      position: relative;
      
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      .content_item {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        .content_item_title {
          margin-bottom: 16px;
          color: $greyG2;
        }
        input {
          border: 0px;
          border-bottom: 1px solid $greyG5;
          width: 342px;
          color: $greyG3;
          background-color: inherit;

          &::placeholder {
            color: $greyG4;
            opacity: 1;
            /* Firefox */
          }
        
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $greyG4;
          }
        
          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $greyG4;
          }
        
          &:focus {
            outline: 0px;
          }
        }
        .div-with-downarrow {
          position: relative;
          .input-with-downarrow {
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 20px;
          }
          .down-arrow {
            position: absolute;
            left: 322px;
            bottom: 4px;
            filter: $primaryP2Filter;
          }
        }
      }
      .custom-tenant-picker-div {
        margin-top: 8px;
        width: 343px;
        max-height: 357px;
        overflow-x: hidden;
        overflow-y: scroll;
        position: absolute;
        z-index: 1;

        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;

        border-radius: 8px;
        background: $greyG8;
        /* Web-Shadow */
        box-shadow: 0px 0px 10px 0px rgba(0, 33, 72, 0.15);
        .tenant-picker-item {
          display: flex;
          width: 343px;
          // height: 56px;
          // padding: 12px 16px;
          padding: 16px;
          align-items: center;
          gap: 10px;

          color: $greyG1;
          text-align: left;
          .tenant-picker-input {
            display: flex;
            height: 48px;
            width: 100%;
            padding: 12px 38px 12px 12px;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;

            border-radius: 8px;
            border: 1px solid $greyG5;
            background: $greyG8;
          }
          .clear-input-btn {
            cursor: pointer;
            position: absolute;
            right: 26px;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: $greyG4;

            > img {
              filter: $greyG8Filter;
              width: 24px;
              height: 24px;
              vertical-align: inherit;
            }
          }
        }
        tenant-picker-item:first-child {
          position: relative;
        }
        .tenant-picker-item:not(:first-child) {
          cursor: pointer;
          max-width: calc(100% - 32px);
          display: block;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0px;
          margin: 16px;
        }
        .tenant-picker-item:not(:first-child):hover {
          color: $primaryP2;
        }
        .create_transaction_separateLine {
          width: 100%;
          // height: 1px;
          padding: 0.5px 0;
          background: $greyG6;
        }
      }
      .custom-tenant-picker-div::-webkit-scrollbar {
        width: 3px;
      }
      .custom-tenant-picker-div::-webkit-scrollbar-track {
        background: $transparent;
      }
      .custom-tenant-picker-div::-webkit-scrollbar-thumb {
        background: $primaryP2;
        border-radius: 2px;
      }
      .receipt_images .content_item_value {
        display: flex;
        .receipt_image_container {
          margin-right: 16px;
        }
        .receipt_image_container, .credit_image_container {
          display: flex;
          flex-direction: column;
          align-items: center;
          .loading_image {
            width: 163.5px;
            height: 163.5px;
            display: flex;
          }
          .image img {
            width: 163.5px;
            height: 163.5px;
            flex-shrink: 0;
          }
          .image_text {
            margin-top: 12px;
            text-align: center;
            color: $greyG3;
          }
          .image_upload_button {
            color: $primaryP2;
          }
          .image img:hover, .image_upload_button:hover {
            cursor: pointer;
          }
          input {
            display: none!important;
          }
        }
      }
      .btn_submit_receipt {
        @if $enableCustomBtnStyle == 1 {
          background: $primaryButtonBackground;
          color: $primaryButtonColor;
        } @else {
          background: $primaryP2;
          color: $greyG8;
        }
        width: fit-content;
        padding: 13px 24px;
        text-align: center;

        border-radius: 100px;
        cursor: pointer;

        &.disabled, &:disabled {
          opacity: 0.6;
          cursor: default;
        }
      }
    }
  }
}

@media (max-width: 767px){
  .create_transaction_breadcrumbs {
    height: 12px;
  }
  .create_transaction_container {
    .create_transaction_content {
      display: block;
      padding: 0px;
      width: 100%;
      background-color: $greyG8;
      border-radius: 8px;
      box-shadow: 0 2px 9px 0 rgba(224, 224, 224, 0.34);
      padding: 4px 24px 24px;
    }
  }
}

@media (max-width: 413px){
  .create_transaction_container {
    .create_transaction_content {
      .row {
        flex-direction: column;
        .col {
          .content_item {
            input {
              width: 100%;
            }
            .div-with-downarrow {
              position: relative;
              .down-arrow {
                position: absolute;
                bottom: 4px;
                left: unset;
                right: 0px;
              }
            }
          }
          .custom-tenant-picker-div {
            width: calc(100% - 30px);
            .tenant-picker-item {
              width: calc(100% - 32px);
            }
            .tenant-picker-item:first-child {
              width: 100%;
            }
          }
          .receipt_images .content_item_value {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .receipt_image_container {
              margin: 0px 0px 16px;
            }
          }
          .btn_submit_receipt {
            width: 100%;
          }
        }
      }
    }
  }
}
