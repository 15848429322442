@import "./src/frontend_common/Theme.scss";

.multi_choice_with_sub_list {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  > .scroll_list {
    flex: 1;
    width: auto;
    height: 100%;
    padding-right: 3px;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: $transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: $primaryP2;
      border-radius: 2px;
    }
    > ul {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      li {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        min-height: 56px;
        padding: 0 2px 0 16px;
        border: none;
        background-color: transparent;
        color: $greyG3;
        cursor: pointer;
        &.active {
          color: $greyG1;
        }
        &.disabled {
          color: $greyG7;
        }
        span {
          height: auto;
          line-height: 24px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }
        img.chevron {
          width: 12px;
          height: 12px;
        }
        img.checkbox {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }
  > .list_separate {
    width: 1px;
    height: 100%;
    &:after {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}
