@import "../../frontend_common/Theme.scss";
.use_couopn_container {
    background-color: $greyG8;
    height: 100%;
    min-height: 100vh;
}
.use_coupon_back_row {
    width: 100%;
    padding-top: 11px;
    margin-bottom: 14px;
}
.coupon_back_btn {
    border: 0px;
    background-color: $greyG8;
}
.code_input_container {
    margin-top: 80px;
    > input {
        background-color: $greyG8;
    }
}