@import "../../frontend_common/Theme.scss";
.use-cash-dollar-section {
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background-color: rgba($color: $primaryP2, $alpha: 0.1);
  position: relative;
  // cursor: pointer;
}
 .background-icon {
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: 
    url("../../assets/images/cash-dollar-top-right.svg"),
    url("../../assets/images/cash-dollar-bottom.svg") ;
  background-repeat: no-repeat, no-repeat;
  background-position: right top, calc(100% - 24px) bottom;
  opacity: 0.7;
  mix-blend-mode: overlay;
 }

.use-cash-dollar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: $greyG4;

  & img {
    width: 24px;
    height: 24px;
    filter: $primaryP2Filter;
  }

  .point-content {
    padding-right: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    
    > img {
      width: 24px;
      height: 24px;
      filter: $primaryP2Filter;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
}
.cash-dollar-title {
  color: $greyG1;
}
.cash-dollar-num-text {
  color: $primaryP1;
}
.use-cash-dollar-modal.home-profile-custom-modal .home-profile-custom-body{
  padding: 24px 0 0;
  background-color: $greyG6;
  > div:not(.use-cash-dollar-modal-content) {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.use-cash-dollar-modal-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: $greyG8;
  border-radius: 12px 12px 8px 8px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .custom-primary-button {
    max-width: 117px;
  }
}
.cash-dollar-input-area {
  width: 100%;
  position: relative;
  border-bottom: 1px solid $greyG5;

  > input {
    width: 100%;
    padding: 0 80px 0 0;
    border: none;
    background-color: $greyG8;
    
    color: $primaryP2;
    line-height: 44px;

    &:focus {
      outline: 0px;
    }
    &.field-text,
    &.field-text:lang(zh-Hant),
    &.field-text:lang(zh-Hans) {
      line-height: 44px;
    }
  }

  .cash-point-balance-text {
    position: absolute;
    top: 20px;
    right: 0px
  }
}
.cash-modal-descrition {
  color: $greyG3;
}
.cash-modal-tips,
.point-to-cash-rule {
  color: $greyG3;
}
.cash-modal-tips.error-field {
  color: $secondaryS4;
}
.point-to-cash-rule {
  color: $greyG4;
}
.cash-modal-secondary-title {
  color: $greyG1;
}
.cash-point-balance-text {
  color: $primaryP2;
}
.custom-primary-button {
  @if $enableCustomBtnStyle == 1 {
    background: $primaryButtonBackground;
    color: $primaryButtonColor;
  } @else {
    background: $primaryP2;
    color: $greyG8;
  }
  text-align: center;
  padding: 13px 0;
  min-height: 48px;
  border-radius: 100px;
  border: none;
  margin-top: 0;

  &:focus {
    outline: 0px;
  }

  &:disabled {
    opacity: 0.6;
  }
}
@media(min-width: 320px) {
  .use-cash-dollar-content {
    gap: 12px;
  }
}

@media(min-width: 1023px) {
  .use-cash-dollar-content .point-content {
    min-width: 190px;
  }
  .use-cash-dollar-section {
    padding: 12px 24px;
  }
}