@import "../../frontend_common/Theme.scss";

.page_container:has(.use-cash-dollar-page) {
  background-color: $greyG8;
  min-height: 100vh;
}
.use-cash-dollar-page {
  background-color: $greyG7;
}
.use-cash-dollar-page-top-section {
  padding: 8px 16px 16px;
}
.use-cash-dollar-title {
}
.use-cash-dollar-page-bottom-section {
  background-color: $greyG8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  > div:first-child {
    gap: 8px;
  }

  .cash-point-balance-text {
    color: $greyG3;
  }
  .cash-dollar-input-area .cash-point-balance-text {
    color: $primaryP2;
  }

  .point-to-cash-rule {
  }
}
.store-selector {
  width: 100%;
  display: flex;
  gap: 16px;

  img {
    width: 24px;
    height: 24px;
    filter: $greyG4Filter;
  }

  & > div {
    min-height: 48px;
    flex: 1;
    display: flex;
    gap: 4px;
    align-items: center;

    padding: 12px;
    border-radius: 8px;
    border: 1px solid $greyG5;
    
    text-align: left;
    color: $greyG3;

    cursor: pointer;
  }
  .store-selected {
    padding: 11px;
    background-color: rgba($primaryP2, 0.1);
    color: $primaryP2;
    border: 2px solid $primaryP2;
    cursor: auto;
    > img {
      filter: $primaryP2Filter;
    }
  }

}
.confirm-use-cash-modal-content {
  margin-top: 8px;
  text-align: center;

  color: $greyG1;
  .check-not-show-again {
    color: $greyG3;
  }
}
.check-not-show-again{
  margin-top: 8px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 24px;
  > img {
    width: 16px;
    height: 16px;
    filter: $greyG3Filter;
  }
}