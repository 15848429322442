@import "./Color.scss";
@import "../frontend_common/Font.scss";

// home banner size
.banner_img {
    width: 100%;
    height: auto;
    aspect-ratio: 2.5;
}

// home section title margin top
.home_title_container {
    margin-top: 40px;
}

// campaign card setting
.campagin_card {
    .campagin_card_image_area {
        width: 100%;
        height: auto;
        aspect-ratio: 1.5;

        // camaign image border
        .campagin_card_mainImage_container {
            .campagin_card_mainImage {
                border-radius: 8px;
            }
        }
        // brand image
        .campagin_card_avatarImage_container {
            position: absolute;
            top: 5.5%;
            right: 2.5%;
            width: 13%;
            height: auto;
            aspect-ratio: 1;
        }

        // campaign left day tag
        .campagin_card_tag_leftDay {
            left: 12px;
            bottom: 0;
            height: 20px;
        }

        // campaign out of stock tag position and color
        .campagin_card_tag_leftDay.campaign_card_out_of_stock_tag_class {
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $secondaryS4;
        }
    }

    // campaign card hide button
    .campagin_card_button_area {
        // display: none;
    }
}

.onlyForYou_card_container {
    // only for you web card image
    .onlyForYou_card_image_container {
        width: auto;
        height: 100%;
        aspect-ratio: 1;
    }

    // out of stock tag color
    .onlyForYou_card_describe_container {
        .onlyForYou_outOfStock {
            background-color: $secondaryS4;
        }
    }
}

.onlyForYou_mobile_slider_container {
    .onlyForYou_mobile_slider_item {
        // only for you mobile card image
        .slider_item_image_container {
            width: 100%;
            height: auto;
            aspect-ratio: 1;

            // out of stock tag position and color
            .slider_item_tag.campaign_card_out_of_stock_tag_class {
                position: absolute;
                left: unset;
                right: 4%;
                background-color: $secondaryS4;
            }
        }
    }
}

// campaign detail image
.campaign_detail_custom_image_slider {
    .slider {
        width: 100%;
        height: auto;
        aspect-ratio: 1.5;
        padding-bottom: 0;
    }
}


@media (min-width: 768px) {
    .campagin_card {
        .campagin_card_image_area {
            // campaign left day tag
            .campagin_card_tag_leftDay {
                bottom: 12px;
                height: 26px;
            }
        }
    }
}
