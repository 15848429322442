@import "../../frontend_common/Theme.scss";

.account-delete-account-section {
    margin-top: 32px;

    .delete-button {
      margin: 0;
      padding: 0px 27px;
      border-radius: 100px;
      border: solid 2px $primaryP2;
      height: 50px;
      width: 120px;
      text-align: center;
      color: $primaryP2;
      background-color: $greyG8;
      &:focus {
        outline: 0;
      }
    }
}

.account-model-body-section {
  margin-bottom: 40px;

  .modal-description {
    margin: 8px 0;
  }
  
  .password-input {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid $greyG5;
    background-color: $greyG8;
    &:focus {
        outline: 0px;
    }
  
    &::placeholder {
        color: $greyG4;
    }
  }
}

.left_btn:disabled {
  opacity: 0.5;
}

@media (max-width: 1023.98px) {
  div.common-shadow-section-container.account-delete-account-section {
    border-radius: 0px;
    padding: 16px;
    margin-top: 8px;
    margin-left: -15px;
    width: calc(100% + 30px);
  }
}
