@import "../../frontend_common/Theme.scss";

.activity-log-table-view {
  .reward_status_table_head {
    justify-content: space-between;

    .reward_status_table_head_item {
      &:nth-child(1) {
        width: calc(100% * 0.48);
      }

      &:nth-child(2) {
        min-width: 134px;
        width: calc(100% * 0.28);
      }

      &:nth-child(3) {
        min-width: 100px;
        width: calc(100% * 0.16);
        text-align: end;
      }
    }
  }
}

.activity-log-table-content {
  .activity-log-table.table {

    >tbody>tr:not(.no-record-tr) {
      justify-content: space-between;
      align-items: center;
    }

    .item_name {
      color: $greyG1;
      width: calc(100% * 0.48);

      display: flex;
      align-items: center;
      gap: 16px;

      .type-icon,
      .type-icon img {
        width: 24px;
        height: 24px;
        min-width: 24px;
        filter: $primaryP2Filter;
      }
      >div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
      .item-sub-title {
        color: $greyG2;
      }
    }

    .item_date {
      min-width: 134px;
      width: calc(100% * 0.28);
    }

    .item-content {
      color: $primaryP2;
      min-width: 95px;
      text-align: end;
      width: calc(100% * 0.16);
      padding: 0;
    }
  }

  tr.no-record-tr {
    justify-content: center;
  }
}

@media (max-width: 1023.98px) {
  .activity-log-table-content {
    .activity-log-table.table .item_name {
      width: auto;
    }
  }
}
