@import "../../frontend_common/Theme.scss";

.campaign_detail_image_slider {
  width: 100%;
}
.slider {
  width: 100%;
  padding-bottom: 66.67%;
  position: relative;
  border: none;
  > .carousel-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .detail_cauousel_prev_icon,
  .detail_carousel_next_icon {
    width: 48px;
    height: 48px;
    filter: $greyG8Filter;
  }
  .detail_carousel_next_icon {
    display: var(--carouselNextControlShow, none);
  }
  .detail_cauousel_prev_icon {
    display: var(--carouselPrevControlShow, none);
  }
  .carousel-control-prev {
    pointer-events: var(--carouselPrevButtonEvent, none);
  }
  .carousel-control-next {
    pointer-events: var(--carouselNextButtonEvent, none);
  }
}

.campaign_detail_image_carousel {
  width: 100%;
}

div.campaign_item {
  width: 100%;
  height: 100%;
}
div.campaign_item .campaign_img {
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
}

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  width: 8px;
  height: 8px;
  border: 0px;
  border-radius: 50%;
  background-color: $greyG8;
  opacity: 1;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
ol.carousel-indicators li.active {
  width: 32px;
  height: 8px;
  border-radius: 100px;
  opacity: 1;
  background-color: $primaryP2;
}

a.carousel-control-prev,
a.carousel-control-next {
  width: 48px;
  margin: 24px;
  color: $greyG8;
  .slider_prev_next_icon {
    background-color: $opacityOP1;
    border-radius: 100%;
  }
}

.campaign_detail_image_modal {
  background-color: $greyG1;
  padding-right: 0 !important;
  .modal-content {
    background-color: $greyG1;
  }
  li.slide {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    width: 100%;
    padding-bottom: 66.66%;
    > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .modal_close_btn {
    border: 0px;
    width: 45px;
    height: 45px;
    margin: 14px 12px 87px auto;
    background-color: $greyG1;
    padding: 0;
    &:focus {
      outline: 0px;
    }
  }
}
.carousel li.thumb {
  border: 0;
  &:hover,
  &:active,
  &.selected {
    border: solid 1px rgba(255, 255, 255, 0.6);
  }
}

@media (max-width: 1023px) {
  .campaign_detail_image_modal .modal-dialog {
    max-width: 100%;
    margin: 0px;

    .thumbs-wrapper ul {
      display: flex;
      justify-content: center;
      padding-left: 0px;
    }
  }
}

@media (min-width: 768px) {
  .campaign_detail_image_modal {
    .modal-dialog {
      max-width: 1024px;
    }
    .carousel-root {
      padding-left: 80px;
      padding-right: 80px;
    }
    .carousel .thumbs-wrapper {
      margin-left: 0px;
      > ul {
        padding-left: 0px;
        > li {
          width: 120px !important;
          position: relative;
          padding-bottom: 80px;
          margin-right: 20px;
          > img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
}
