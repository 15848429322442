@import "./src/frontend_common/Theme.scss";

.coupon_qrcode_container {
  width: 300px;
  position: relative;
  background-color: $greyG8;
  > img {
    position: absolute;
    &.coupon_background_down {
      top: 336px;
    }
  }
  .dividing_line {
    margin-top: 0;
  }
}
.coupon_state_section {
  padding: 9px 0 7px 16px;
  display: flex;
  align-items: center;
  height: 65px;
  width: 300px;
  position: relative;
  background-color: $greyG8;
}
.coupon_text {
  color: $primaryP1;
  background-color: $greyG8;
}
.used_or_expired_state {
  display: flex;
  justify-content: center;
  padding: 0px;
  width: 300px;
}
.coupon_qrcode_message{
  background-color: $secondaryS1;
  color: $greyG8;
  text-align: center;
  padding: 12px 10px;
  margin: 0;
  width: 268px;
}
.coupon_qrcode_message_block {
  background-color: $greyG8;
  padding-left: 16px;
  width: 300px;
}
.coupon_qrcode_block {
  padding: 21px 0 0 0;
  display: flex;
  justify-content: center;
  position: relative;
  width: 300px;
  background-color: $greyG8;
  > svg {
    width: 236px;
    height: 236px;
  }
}
.coupon_barcode_block {
  padding-bottom: 0px;
  > svg{
  width: 268px;
  height: 108px;
}}
.coupon_code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 0;
  position: relative;
  width: 300px;
  color: $greyG3;
  background-color: $greyG8;

  & div {
    width: 268px;
    text-align: center;
    word-wrap: break-word;
  }

  .view_large_barcode {
    color: $primaryP2;
    cursor: pointer;
  }
}
svg {
  width: 100%;
  height: 100%;
}
.copy_code_btn {
  width: 268px;
  border-radius: 27px;
  border: 0;
  background-color: $greyG8;
  color: $primaryP2;
  border: 2px solid $primaryP2;
  text-align: center;
  
  padding: 13px 10px;
  justify-content: center;
  &:focus,
  &:active {
    outline: 0;
  }

  &.apply_code_btn {
    background-color: $primaryP2;
    color: $greyG8;
  }
}

@media (max-width: 1023px) {
  .coupon_qrcode_container {
    width: 100%;
    // height: 456px;
    background-color: $greyG8;
    border-radius: 0px 0px 8px 8px;
  }
  .coupon_qrcode_block > svg {
    width: 180px;
    height: 180px;
  }
  .coupon_qrcode_block {
    padding: 20px 0 10px 0;
    width: 100%;
  }
  .coupon_barcode_block {
    padding: 0;

   > svg {
    max-width: 311px;
    height: 125px;
    width: 100%;
  }}
  .coupon_state_section {
    justify-content: left;
    margin: 0 0 0 5px;
    padding: 0;
    height: auto;
    color: $greyG3;
  }
  .coupon_qrcode_message_block {
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    width: auto;
    max-width: 300px;
  }
  .coupon_qrcode_message {
    width: auto;
  }
  .coupon_qrcode_container > img {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    &.coupon_background_down {
      top: 390px;
    }
  }
  .coupon_code {
    padding: 15px 0;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
  }
  .barcode_message {
    display: none;
  }
  .coupon_used_or_expired {
    display: none;
  }
  .coupon_vaild_message {
    margin-left: 12px;
    margin-bottom: 16px;
    color: $greyG3;
  }
  .used_or_expired_state {
    width: 100%;
    margin-top: 4px;
    &.coupon_vaild_message {
      position: absolute;
    }
  }
}
