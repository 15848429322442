@import './src/frontend_common/Theme.scss';

.user-section-container {
  position: relative;
  margin-top: 31px;
}

.coupon-section-container {
  min-height: calc(100vh - var(--page-nav-height) - var(--app-banner-height) - 52px - 31px - 10px - 59px);
}
.user_container_background {
  background-color: $greyG7;
}
.user_container {
  // max-width: 100%;
  padding-bottom: 10px;
  width: 100%;
  // margin-top: 39px;
}
.user_nav_container {
  width: 100%;
  background-color: $greyG8;
  overflow-x: auto;
}
.user_nav {
  // margin-left: 11px;
  // padding-top: 39px;
  padding: 4px 10px;
  flex-wrap: nowrap !important;

  // a.long-text {
  //   width: 183px;
  // }

  > div > div.nav-item:nth-child(2) {
    // width: 183px;
    text-align: center;
  }

  > div > div.nav-item:nth-child(3) {
    // width: 140px;
    text-align: center;
  }

  div.nav-item {
    padding: 10px 16px;
    &:hover{
      border-radius: 17px;
      color: $primaryP2;
      div.nav-link.active {
        color: $primaryP1;
      }
    }
    div.nav-link {
      padding: 0px;
      color: $greyG4;
      white-space: nowrap;
      cursor: pointer;
      &:not(.active):hover{
        color: $primaryP1;
      }
      &.active {
        color: $primaryP1;
        border-bottom: 2px solid $primaryP1;
        cursor: default;
        pointer-events: none;
      }
    }
  }
}
.user-nav-dropdown {
  margin-left: -15px;
  width: calc(100% + 30px);
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 767px) {
  .coupon-section-container {
    min-height: calc(100vh - var(--page-nav-height) - var(--app-banner-height) - 52px - 31px - 10px - 126px);
  }
  div.user_nav {
    display: none;
  }
}
