@import "../../frontend_common/Theme.scss";

.account-change-password-section {
    margin-top: 32px;
}

.account-password-section {
    display: flex;
    position: relative;
    width: 350px;

    .password-input {
        width: 100%;
        border: 0px;
        border-bottom: 1px solid $greyG5;
        background-color: $greyG8;
        &:focus {
            outline: 0px;
        }

        &::placeholder {
            color: $greyG4;
        }
    }

    .password-visible-btn {
        position: absolute;
        right: 0px;
        bottom: 1px;
        border: 0;
        background-color: rgba(0, 0, 0, 0);
        filter: $primaryP2Filter;

        &:focus {
            outline: 0px;
        }
    }
}

.change-password-infomation {
    max-width: 351px;
    margin-top: 7px;
    color: $greyG4;
}

@media (max-width: 1023.98px) {
    .account-password-section {
        width: 100%;
        min-width: 320px;
    }
}