@import "../../frontend_common/Theme.scss";

.bottom_block {
  width: 100%;
  .bottom {
    background-color: $greyG6;
  }
}

.points_required_section {
  margin: 12px 0 11px 20px;
}

.btn_section {
  @if $enableCustomBtnStyle ==1 {
    background: $primaryButtonBackground;
    &:disabled {
      opacity: 0.6;
    }
  } @else {
    background: $secondaryS2;
    &.loading_animation_btn {
      background: $secondaryS2;
    }
    &:disabled {
      background: $greyG4;
    }
  }
  align-self: center;
  justify-content: center;
  padding: 13px 10px;
  border-radius: 48px;
  display: flex;
  border: 0px;
  min-width: 136px;
  min-height: 48px;

  &.tick_animation_btn,
  &.loading_animation_btn {
    height: 48px;
    padding: 0;
  }
  &.tick_animation_btn {
    background: $greyG8;
  }
  &:focus {
    outline: 0;
  }
}

.bottom_btn {
  border: 0;
  align-self: center;
  color: $greyG8;

  &:hover {
    color: $greyG8;
    text-decoration: none;
  }
  &:disabled {
    color: $greyG8;
  }
  &:focus {
    outline: 0;
  }
}

label.bottom_btn {
  text-align: center;
  color: $greyG8;
  align-self: center;
  margin-bottom: 0px;
  cursor: pointer;
}

.points_required {
  color: $primaryP2;
  margin-bottom: 0px;
}

.point {
  margin-bottom: 0;
}

.bottom {
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 20px;
}

.earning_type {
  color: $secondaryS2;
}
button.earning_type {
  @if $enableCustomBtnStyle == 1 {
    background: $secondaryButtonBackground;
  } @else {
    background: $secondaryS2;
  }
}
.coupon_type{
  color: $primaryP2;
}
button.coupon_type {
  @if $enableCustomBtnStyle == 1 {
    background: $primaryButtonBackground;
  } @else {
    background: $primaryP2;
  }
}

@media (max-width: 767px) {
  // .bottom {
  //     padding-right: 15px;
  // }
  .btn_section:not(.unavailable) {
    width: 136px;
    padding: 0;
  }
}
