@import '../../frontend_common/Theme.scss';

.page_container:has(.level-rule-mobile-page-container),
.page_container:has(.offer_container),
.page_container:has(.home_page){
  background-color: $greyG8;
}
.level-rule-mobile-page-container {
  padding-bottom: 1px;
}
.level-rule-mobile-page {
  .level-rule-item-section:not(:last-child) {
    margin-bottom: 24px;
  }
  .level-rule-item-section:not(:first-child) {
    margin-top: 24px;
  }

  .split-line {
    width: 100%;
    height: 8px;
    margin-left: -15px;
    width: calc(100% + 30px);
    background-color: $greyG7;
  }

  .level-rule-title {
    color: $primaryP1;

    margin: 0 0 12px ;
    padding-top: 8px;
  }

  .level-rule-second-title {
      color: $primaryP1;
      margin-bottom: 8px;
  }

  .level-rule-detail-message { 
    color: $greyG3;
    > p {
        margin: 0;
    }
  }

  .level-rule-table {
    margin-top: 24px;
    thead {
      background-color: $greyG4;
      color:$greyG1;
    }
  }
}