@import "./src/frontend_common/Theme.scss";

div.store_card {
  position: relative;
  display: block;
  margin-bottom: 24px;
  margin-right: 24px;

  height: auto;
  padding: 16px;
  background-color: $greyG8;
  border-radius: 8px;
  border: none;

  cursor: pointer;
  .card-img-top {
    border-radius: 8px;
    border: 1px solid $greyG5;
  }
  .card-body {
    margin-top: 16px;
    padding: 0;
    .store_title {
      height: 70px;
      .store_name {
        margin: 0px;
      }
      .store_brand {
        margin: 4px 0 0 0;
      }
    }
    .store_sub_category {
      margin: 16px 0 0 0;
      height: 26px;
    }
    .store_location {
      margin: 16px 0 0 0;
      height: 16px;
    }
    .store_offers {
      margin: 8px 0 0 0;
      height: 60px;
      padding: 8px 0 0 0;
      border: none;
      border-top: 1px solid $greyG5;
      background: transparent;
    }
  }

  .store_name {
    color: $greyG1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .store_brand {
    color: $greyG3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .store_sub_category {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    span {
      border-radius: 2px;
      border: 1px solid $greyG6;
      padding: 2px 4px;
      color: $greyG3;
      background-color: $greyG7;

      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &.ellipsis {
        border: none;
        background-color: transparent;
      }
    }
  }
  .store_location {
    display: flex;

    img {
      width: 16px;
      height: 16px;
    }
    .location_name {
      flex: 1;
      width: auto;
      margin-left: 4px;

      color: $primaryP2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .distance {
      color: $greyG3;
    }
  }
  .store_offers {
    color: $greyG3;
    &.disable {
      color: $greyG4;
      text-align: center;
    }
    .store_offer {
      margin-top: 8px;
      width: 100%;
      height: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
}

@media (max-width: 767px) {
  div.store_card {
    margin-right: 0px;
    margin-bottom: 8px;
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row;
    .card-img-top {
      margin-top: 16px;
      margin-right: 12px;
      width: 90px;
      height: 60px;
    }
    .card-body {
      width: auto;
      flex: 1;
      max-width: calc(100% - 100px);
      .store_title,
      .store_sub_category,
      .store_location,
      .store_offers {
        width: 100%;
        height: auto;
      }
      .store_offers.disable {
        text-align: left;
      }
    }
  }
}
