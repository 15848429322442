@import "./src/frontend_common/Theme.scss";

.point_required_section {
  width: 100%;
  background-color: $greyG8;
  margin-bottom: 53px;
}

.block_label {
  width: 100%;
  color: $primaryP1;
  margin: 24px 0 8px 16px;
}

// .reuired_points_section {
//   margin-bottom: 22px;
// }

.required_points {
  width: 285px;
  color: $primaryP2;
  margin: 2px 0 0 16px;
}

.reduce_btn,
.multiply_btn {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 50%;
  border: 0;
  justify-content: center;
  padding: 0;
  &:focus {
    outline: 0px;
  }
}

.reduce_btn,
.multiply_btn {
  &:disabled {
    background-color: #c5c5c5;
  }
}
.minus,
.plus {
  color: $greyG8;
  align-self: center;
  margin: 0;
}

.calculate_section {
  border-top: solid 1px $greyG5;
  border-bottom: solid 1px $greyG5;
  margin-top: 23px;
  flex-direction: column;
  padding: 20px 16px;
}
.calculate_label {
  color: $greyG3;
  margin-left: 16px;
}
.calculate_points,
.result_points {
  text-align: right;
  color: $greyG3;
  margin-right: 20px;
}
.result_points {
  color: $primaryP2;
}

.buy_section {
  justify-content: center;
  height: 87px;
  align-content: center;
  padding: 19px 20px 18px;
}

button.buy_btn {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 0;
  align-self: center;

  @if $enableCustomBtnStyle == 1 {
    background: $primaryButtonBackground;
    color: $primaryButtonColor;
    &:disabled {
      opacity: 0.6;
    }
  } @else {
    background: $primaryP2;
    &:disabled {
      background: $secondaryS1;
    }
  }

  &:disabled {
    .buy_label {
      cursor: auto;
    }
  }
  &:focus {
    outline: 0;
  }
}

.buy_label {
  text-align: center;
  color: $greyG8;
  margin: 0;
  cursor: pointer;
}

.quantity_row {
  justify-content: space-between;
  padding: 2px 15px;
  // margin-bottom: 21px;
}

.numbers {
  text-align: center;
  color: $primaryP2;
  margin: 0;
}

.point_available_section,
.required_points_section,
.balance_section {
  justify-content: space-between;
}

.red_label {
  color: red;
}

.icon {
  width: 48px;
  height: 48px;
  filter: $primaryP2Filter;
}
