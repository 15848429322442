@import "../frontend_common/Theme.scss";
.my-account-container {
  position: relative;
}

.update-success-toast {
  //   position: absolute;
  top: 110px;
  // left: 40%;
  left: 0;
  //   width: calc(100% - 240px);
  //   max-width: calc(100% - 240px);
  position: fixed;
  right: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051;
  .save-toast-main-content {
    max-width: 386px;
  }

  .toast-body {
    padding: 0px 12px;
    background: $opacityOP2;
    color: $greyG8;

    box-shadow: 0 2px 6px 0 $opacityOP1;

    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;
  }

  .toast-body label {
    padding-top: 5px;
  }
}

@media (max-width:1024px) {
  .update-success-toast {
    top: auto;
    bottom: 80px;
  }
}
@media (max-width:368px) {
  .update-success-toast {
    top: auto;
    bottom: 102px;
  }
}
