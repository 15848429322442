@import "../../frontend_common/Theme.scss";

.coupon_qrcode_section, div.staff_use_bottom_bar {
    display: none;
}
.coupon_qrcode_section {
    margin-top: 11px;
    margin-bottom: 43px;
}
.used_tag,
.expired_tag,
.mobile_coupon_used_tag,
.mobile_coupon_expired_tag {
    color: $greyG8;
    background-color: $greyG3;
    margin: 20px 0 0 20px;
    padding: 2px 8px;
}
.expired_tag,
.mobile_coupon_expired_tag {
    background-color: $greyG4;
}
.mobile_coupon_used_tag,
.mobile_coupon_expired_tag {
    display: none;
}
.dividing_line {
    border: 1px solid $greyG5;
    width: calc(100% - 32px);
    margin: 16px;
}

.coupon_detail_dividing {
    border: 1px solid $greyG5;
    margin: 8px 10px 14px;
}
.bottom-select-store-btn {
    @if $enableCustomBtnStyle ==1 {
        background: $primaryButtonBackground;
    } @else {
        background: $primaryP2;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 11px;

    color: $greyG8;
    width: 100%;
    min-height: 48px;
    border-radius: 100px;
    cursor: pointer;
}

@media (max-width: 1023px) {
    .coupon-detail-img-slider{
        width: calc(100% - 32px);
        margin: 0 auto;
        
        #detail_general_slider,
        .campaign_img {
            border-radius: 8px;
            border: none;
        }
    }

    .mobile_left_section {
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px #0000001A;
    }
    .coupon_qrcode_section, div.staff_use_bottom_bar {
        display: block;
    } 
    .coupon_qrcode_section {
        margin-top: 3px;
    }
    .coupon_code {
        padding-top: 0px;
    }
    .used_tag,
    .expired_tag {
        display: none;
    }
    .mobile_coupon_used_tag,
    .mobile_coupon_expired_tag {
        display: block ;
        margin: 8px 0 16px 33px;
    }
    div.staff_use_bottom_bar {
        position: sticky;
        bottom: 0px;
        z-index: 20;
        border-top: solid 1px #d1d1d1;
        box-shadow: 0px -2px 4px 0px #9494944D;

        &:has(.staff_use_container .scanner-modal) {
            position: static;
        }
    }
}