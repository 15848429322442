@import "../frontend_common/Theme.scss";

.store_detail_container {
  background-color: $greyG7;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 60px;
  padding-left: 45px;

  div.left_section {
    background-color: $greyG8;
    width: 62%;
  }
  .background_color_g6 {
    background-color: $greyG6;
  }

  .offer_title {
    margin: 24px 16px 0 16px;
    color: $primaryP1;
  }

  .sub_title {
    margin: 16px;
    color: $greyG2;
  }
  .store_sub_category {
    margin: 0 16px 24px 16px;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 8px;
    span {
      height: 32px;
      padding: 4px 8px;
      background-color: $greyG7;
      border-radius: 2px;
      border: 1px solid $greyG6;

      color: $greyG3;

      cursor: pointer;
    }
  }

  .custom_separate.sub_dividing {
    height: 1px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: $greyG5;
  }
  .dividing {
    height: 8px;
    background-color: $greyG6;
  }

  .location {
    margin: 24px 16px 0 16px;
    height: 24px;
    color: $primaryP1;
  }

  .store_location {
    margin: 8px 16px 0 16px;
    height: auto;
    display: flex;
    color: $greyG3;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
    }
    .location_name {
      flex: 1;
      width: auto;
      margin-left: 4px;

      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .contact {
    margin: 24px 16px;
    width: 100%;
    height: 78px;
    padding: 16px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: $greyG6;
    div.contact-phone,
    div.contact-email {
      flex: 1;
      max-width: 50%;
      height: 46px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      .contact-phone-text,
      .contact-email-text {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 16px;
        overflow: hidden;
        label {
          margin-bottom: 2px;
          cursor: pointer;
        }
        span {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .custom_separate {
      margin-top: 16px;
      margin-bottom: 16px;
      width: 1px;
      height: 100%;
    }
  }

  .contact-empty {
    margin-top: 24px;
    width: 100%;
    height: 0;
  }

  .store_detail_title {
    margin: 24px 16px 0 16px;
    color: $primaryP1;
  }

  .mobile_related_campaign {
    .campaign_list {
      display: none;
      width: 100%;
      > .campaign_list_scroll_view {
        height: auto;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }
  }

  .campaign_list {
    display: flex;
    flex-direction: column;
    .campaign_list_title {
      padding: 24px 16px;
      color: $primaryP1;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: $greyG8;
    }
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: $transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: $primaryP2;
      border-radius: 2px;
    }
    > .campaign_list_scroll_view {
      padding: 0 16px;
      height: 567px;
      overflow-x: hidden;
      overflow-y: scroll;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: $greyG8;
      div.campagin_card {
        margin-top: 0px;
        margin-bottom: 10px;
        padding-bottom: 14px;
        cursor: pointer;
        .campagin_card_button_area {
          display: none;
        }
      }
    }
  }

  .short_description {
    background-color: $greyG8;
    // margin: 4px 20px 20px 20px;
    padding: 24px 16px;
    color: $greyG3;
    width: 100%;
  }
  .short_description p {
    margin-bottom: 0px;
  }

  div.right_section {
    background-color: $greyG7;
    width: 30%;
    padding: 0;
  }

  .media {
    margin-bottom: 8px;
    margin-top: 12px;
  }

  div.share_block {
    justify-content: center;
  }

  .media-body {
    align-self: center;
  }

  .float_right_section {
    position: sticky;
    top: 40px;
    background-color: $greyG7;
    padding: 0 16px;

    &:has(.scanner-modal) {
      position: static;
    }

    .shared_container {
      margin-top: 24px;
    }
  }

}
@media (max-width: 1024px) {
  .store_detail_container {
    max-width: 100%;
    .campaign_detail_image_slider {
      width: 100%;
    }
  }
}

@media (max-width: 1023px) {
  .store_detail_container {
    padding-bottom: 0px;
    padding-left: 15px;
    div.right_section {
      display: none;
    }
    div.brand_container,
    div.nav_row,
    .offer_tag,
    .media {
      margin-left: 0px;
    }
    .offer_title {
      margin-left: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      margin-bottom: 0px;
    }
    .brand_name {
    }
    .contact {
      margin: 0 0 4px 0;
      border-radius: 0;
      background: transparent;
      div.contact-phone,
      div.contact-email {
        justify-content: flex-start;
      }
    }
    div.share_block {
      display: block;
      margin-top: 50px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .mobile_related_campaign {
      .campaign_list {
        display: block;
      }
    }
    div.fixed-bottom {
      display: block;
      z-index: 998;
    }
    .short_description {
      padding: 24px 16px;
    }
    div.mobile_bottom_bar {
      display: flex;
    }
    div.nav_row a {
      color: $greyG3 !important;
      text-decoration: none;
      margin: 0 8px !important;
    }
    div.nav_row a:hover {
      color: $primaryP2;
    }


    .offer_title {
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 8px;
      margin-top: 12px;
    }

    .short_description {
    }

  }
}
