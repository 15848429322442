@import "./src/frontend_common/Theme.scss";

.qrcode {
  width: 239px;
  height: 239px;
  border-radius: 9px;
  border: solid 1px #dcdcdc;
}
.google_play_btn,
.apple_store_btn {
  border: 0;
  object-fit: contain;
  &:focus {
    outline: 0;
  }
}
.qrcode_message {
  margin-top: 6px;
}
.qrcode_modal > div > div {
  width: 325px;
  height: 361px;
  align-items: center;
}
.qrcode_modal > div {
  justify-content: center;
}
div.qrcode_body {
  padding: 8px 32px;
}
div.store_btns {
  justify-content: space-around;
  padding: 0;
  border-top: 0px;
}

.download_qrcode_view {
  margin-bottom: inherit !important;
}
