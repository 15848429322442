@import "./Color.scss";

$inboxActiveBackground: #f4f4f4;

$greyishBrown: rgb(74, 74, 74);
$transparent: rgba(0, 0, 0, 0);
$black: rgb(43, 43, 43);
$veryLightPinkThree: rgb(209, 209, 209);
$veryLightPink: rgb(238, 238, 238);
$dustyOrange: rgb(250, 146, 48);
// font
// $fontBold: 'AvenirNext-Bold';
// $fontDemiBold: 'AvenirNext-DemiBold';
// $fontHeavy: 'AvenirNext-Heavy';
// $fontMedium: 'AvenirNext-Medium';
// $fontItalic: 'AvenirNext-Italic';
// $fontRegular: 'AvenirNext-Regular';
// $fontRegular: 'Poppins-Regular';      // 300
// $fontMedium: 'Poppins-Medium';        // 400
// $fontSemiBold: 'Poppins-SemiBold';    // 500
// $fontBold: 'Poppins-Bold';            // 600
// $fontExtraBold: 'Poppins-ExtraBold';  // 700
// $fontHeavy: 'Poppins-Black';          // 800

$containerMarginTop: 12px;
$containerMarginLeft: 15px;
$containerMarginRight: 15px;

$zIndexSlideMenu: 1000;
$zIndexAppNav: 999;
$zIndexFloatingTitle: 900;
