@import './src/frontend_common/Theme.scss';

.productContainer {
  width: 100%;
  height: auto;
  padding: 8px;
  border-radius: 8px;
  background-color: $greyG7;
  .product {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-between;
    .productText {
      color: $greyG3;
    }
  }
  .discount {
    margin-top: 8px;
  }
  .price {
    margin-top: 8px;
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
  .priceText {
    color: $greyG1;
    &.priceDelete {
      color: $greyG4;
      text-decoration-line: line-through;
    }
    &.refundColor {
      color: $secondaryS3;
    }
  }
}
