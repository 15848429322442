@import "./src/frontend_common/Theme.scss";

.transaction_history {
  padding-top: $containerMarginTop;
  padding-bottom: $containerMarginTop;
  background-color: $greyG6;
  .title {
    margin-left: $containerMarginLeft;
    margin-right: $containerMarginRight;
    height: 28px;
    color: $primaryP1;
  }
  .transaction_history_detail {
    margin-top: $containerMarginTop;
  }
}
