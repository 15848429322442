@import "../frontend_common/Theme.scss";

.my_card_container {
  display: flex;
  padding: 24px;
  justify-content: center;
}

.my-card-background {
  width: 320px;
  height: 500px;
  border-radius: 12px;
  background-color: $primaryP2;
  color: $greyG1;
}

.my_card_content {
  position: absolute;
  padding-top: 44px;
  display: flex;
  flex-direction: column;
}

.my_card_logo {
  align-self: center;
  width: 103px;
  height: 40px;
}

.my_card_user_name {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  margin: 24px 0 0;

  text-align: center;
}

.my_card_user_id {
  margin-top: 4px;

  text-align: center;
}

.my_card_qrcode_view {
  width: 190px;
  height: 190px;
  display: flex;
  margin: 16px auto 0;
  padding: 20px;
  background: $greyG8;
  border-radius: 22.7px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);

}

.my_card_available_points {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 16px;
  margin-bottom: 4px;

  text-align: center;

  > p {
    display: inline-block;
    margin: 0;
  }
}

.my_card_tier {
  text-align: center;
}

div.my-card-no-level {
  background-color: $primaryP2;
  opacity: 1;
}

div.my-card-no-level-text,
p.my-card-no-level-text {
  color: $greyG8;
}

@media (max-width: 767px) {
  .my_card_container {
    padding-top: 3px;
  }
}
