@import "../../frontend_common/Theme.scss";

.picker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.35);
    color: $greyG2;
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    background: $greyG8;
    width: 277px;

    .month:hover {
        border-radius: 8px;
        background-color: $greyG6;
    }
        
    .month {
        padding: 1rem;
        border: solid 1px transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $greyG2;
    }
        
    .active {
        border: none;
        outline: none;
        border-radius: 8px;
        background: $greyG4 !important;
        color: $primaryP2 !important;
    }
        
    .year-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom:  1rem;
        margin-top: 0;
        width: 60%;
    }
        
    .month-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;
    }
        
    .birthday-button {
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
    }
    
    .birthday-arrow {
        width: 24px;
        height: 24px;
    }

    .birthday-arrow.rotate {
        transform: rotate(180deg);
    }
        
    .picker-container::selection {
      background-color: transparent;
    }

    .year-dropdown {
        position: absolute;
        max-height: 200px;
        top: 4rem;
        align-self: center;
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.35);
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
        background: $greyG8;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .year-dropdown::-webkit-scrollbar {
        display: none;
    }

    .year {
        padding: 0.5rem;
    }

    .button-container {
        margin-top: 1rem;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        width: 100%;
    }

    .birthday-confirm-button {
        padding: 0.5rem;
        margin-left: 1rem;
        color: $greyG1;
    }
}
