@import "./src/frontend_common/Theme.scss";

div.coupon_card {
  display: block;
  margin-bottom: 21px;
  margin-right: 21px;
  border-radius: 14.9px;
  position: relative;
  border: none;
  .virtual_coupon_select {
    position: absolute;
    top: 45%;
    right: 0px;
    height: 32px;
    max-width: 80%;
    padding: 4px 4px 4px 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border: 0px;
    border-radius: 16px 0px 0px 16px;
    background: $secondaryS4;
    cursor: pointer;
    span {
      color: $greyG8;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
}
.coupon_img {
  padding-bottom: 66.67%;
  position: relative;
  overflow: hidden;
  &.coupon_dimmed::after {
    border-top-left-radius: 14.9px;
    border-top-right-radius: 14.9px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: $greyG4;
    width: 100%;
    height: 100%;
    opacity: 0.9;
  }
}
img.card_img {
  border-top-left-radius: 14.9px;
  border-top-right-radius: 14.9px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}
.coupon_image_border {
  border: dashed 1px #979797;
  margin-left: 17px;
  margin-right: 17px;
}
div.coupon_card_body {
  padding: 15px 17px 17px;
  background-color: $greyG8;
  border-radius: 0 0 8px 8px;
}
.coupon_expiry_date {
  color: $primaryP2;
}
.less_6_days_expiry_date {
  color: $greyG4
}
.available_status,
.less_6_days {
  color: $primaryP2;
  margin-top: 3px;
}
.less_6_days {
  color: $secondaryS4;
}
.coupon_message {
  color: $greyG3;
  margin-top: 9px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
}
.coupon_oval_container {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 13px;
  right: 17px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(61, 61, 61, 0.5);
}
.coupon_oval_icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-bottom: 3px solid #a60101;
  position: absolute;
  top: 22px;
  left: -3px;
}
.new_label {
  background-color: $secondaryS4;
  padding: 2px 15px;
  color: $greyG8;

  position: absolute;
  top: 25px;
  left: -3px;
}
.coupon_used_tag,
.coupon_expired_tag {
  background-color: rgb(254, 141, 141);
  padding: 2px 5px;
  margin-right: 10px;
}
.coupon_expired_tag {
  background-color: rgb(252, 141, 87);
}

@media (max-width: 1023px) {
  div.coupon_card {
    margin-right: 0px;
  }
}
@media (max-width: 1200px) and (min-width: 1024px) {
  div.coupon_card {
    .virtual_coupon_select {
      top: 40%;
    }
  }
}
@media (max-width: 575px) {
  .available_status,
  .less_6_days {
  }
  .coupon_expiry_date, .less_6_days_expiry_date {
  }
  .coupon_message {
    height: 66px;
  }
  div.coupon_card {
    .virtual_coupon_select {
      bottom: 160px;
      top: auto;
    }
  }
}
@media (max-width: 350px) {
  div.coupon_card {
    .virtual_coupon_select {
      bottom: auto;
      top: 50px;
    }
  }
}
.coupon_dimmed {
  color: $greyG4;
}
