@import './src/frontend_common/Theme.scss';

.discountContainer {
  width: 100%;
  height: auto;
  padding: 8px;
  border-radius: 8px;
  background-color: $greyG7;
}

.discount {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  .discountSpan {
    padding-left: 8px;
    padding-right: 8px;
    background-color: $greyG5;

    color: $greyG3;
  }
}
