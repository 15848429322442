div.account-direct-marketing-section {
    margin-top: 32px;
}

.common-item-value.accept-receive-marketing-via-email {
    max-width: 280px;
}

@media (max-width: 1023.98px) {
    div.common-shadow-section-container.account-direct-marketing-section {
        margin-top: 8px;
        border-radius: 0px;
        margin-left: -15px;
        width: calc(100% + 30px);

        .common-account-col {
            max-width: 100%;
        }
    }
}