@import "./src/frontend_common/Theme.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.page_container {
  background-color: $greyG7;
  margin: auto;
  padding: calc(var(--page-nav-height) + var(--app-banner-height)) 0 0 0;
  max-width: 100%;
  min-height: calc(100vh - 80px);
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
  --app-banner-height: 0px;
  --page-nav-height: 69px;
}

div.app_container {
  min-height: 100%;
  background-color: $greyG8;
}

div.app_navigator_container {
  position: fixed;
  top: var(--app-banner-height);
  width: 100%;
  height: var(--page-nav-height);
  background: $navigatorBackground;
  z-index: $zIndexAppNav;
  &.g6_background {
    @if $navigatorBackground == $greyG8 and $navigatorTextColor !=$greyG8{
      background: $greyG6;
    }
  }
}

@media (max-width: 767px) {
  #root {
    --app-banner-height: 66px;
  }
  div.page_container {
    padding-top: calc(var(--page-nav-height) + var(--app-banner-height));
    min-height: calc(100vh - 125px);
  }
}

img.p2_color_image {
  filter: $primaryP2Filter !important;
}

img.g3_color_image {
  filter: $greyG3Filter !important;
}

img.g4_color_image {
  filter: $greyG4Filter !important;
}

.not_selectable {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.custom_separate {
  display: flex;
  &:after {
    content: '';
    margin: 0;
    flex: 1;
    width: auto;
    height: auto;
    background-color: $greyG4;
    pointer-events: none;
  }
}

.rotate_0 {
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  -o-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}

.rotate_90 {
  -webkit-transform: rotate(90deg) !important;
  -moz-transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important;
  -o-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}

.rotate_180 {
  -webkit-transform: rotate(180deg) !important; /* Chrome, Safari, Opera */
  -moz-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  -o-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important; /* Standard syntax */
}

.rotate_270 {
  -webkit-transform: rotate(270deg) !important;
  -moz-transform: rotate(270deg) !important;
  -ms-transform: rotate(270deg) !important;
  -o-transform: rotate(270deg) !important;
  transform: rotate(270deg) !important;
}
