@import './src/frontend_common/Theme.scss';


.inbox_title_container {
  padding: 24px;
  width: 100%;
  height: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
  background-color: $greyG8;
  .title_inbox {
    color: $greyG1;
  }
  .title_action {
    display: flex;
    flex-flow: row;
    button {
      padding: 0;
      border: none;
      background: none;
      outline: none;
      color: $primaryP1;
      box-shadow: none;
      &:disabled {
        color: $secondaryS1;
      }
      &:not(:disabled):not(.disabled):hover,
      &:not(:disabled):not(.disabled):active {
        color: $primaryP2;
        background-color: inherit;
        box-shadow: none;
      }
    }
    .title_delete {
      margin-right: 24px;
      color: $secondaryS4;
      &:disabled {
        color: $greyG4;
      }
    }
  }
}
