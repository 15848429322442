@import "../../frontend_common/Theme.scss";
.scanner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
}
.scanner_close_btn {
  display: flex;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0px;
  background-color: $greyG4;
  border-radius: 50%;
  margin: 8px 24px auto auto;
  justify-content: center;
  align-items: center;
  > img {
    width: 24px;
    height: 24px;
  }
}

.scanner-mask-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.scanner-container {
  display: flex;
  flex-direction: row;
}

.scanner-box {
  width: 240px;
  height: 240px;
  border: 2px solid $primaryP2;
}

.scanner-text-container {
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  align-items: flex-start;
}

.scanner-title {
  color: white;
}

.scanner-subtitle {
  color: white;
}
.scan-state {
  margin-top: 35px;
}

.scanner-horizontal-mask {
  width: calc((100vw - 240px) / 2);
  height: 100%;
  background-color: $opacityOP1;
}

.scanner-vertical-mask {
  height: 100%;
  width: 100%;
  background-color: $opacityOP1;
}