@import "./src/frontend_common/Theme.scss";

div.onlyForYou_card_container {
  display: flex;
  height: 100%;
  width: 100%;
}

div.onlyForYou_card_image_container {
  position: relative;
  // height: 100%;
  // width: 54%;

  .onlyForYou_card_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .onlyForYou_card_avatar {
    position: absolute;
    height: 15%;
    width: 15%;
    top: 4%;
    right: 3%;
    object-fit: cover;

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.onlyForYou_card_image_container .onlyForYou_card_dimmed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $greyG8;
  border-radius: 8px;
  opacity: 0.6;
}

.onlyForYou_card_describe_container {
  position: relative;
  height: 100%;
  width: 46%;
}

.onlyForYou_card_describe {
  margin-top: 16%;
  margin-right: 8%;
  margin-left: 8%;

  display: block;
  display: -webkit-box;
  height: 38px * 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $greyG8;
}

.onlyForYou_card_tag {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-left: 8%;
  padding: 2px 8px;
  height: 7%;
  top: 65%;
  background-color: $greyG8;
  // background-color: $greyG1;
  // opacity: 0.5;
  color: $greyG3;
}

.onlyForYou_outOfStock {
  top: 55%;
  color: $greyG8;
}

button.onlyForYou_card_button {
  position: absolute;
  left: 8%;
  bottom: 10%;
  width: 240px;
  display: flex;

  border-radius: 25px;
  border: none;
  align-items: center;
  justify-content: center;
  color: $primaryP2;
  background-color: $greyG8;
  padding: 13px 10px;

  &:hover, &:focus,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background-color: $greyG8;
    color: $primaryP2;
    border: 0;
  }
  &:disabled {
    background-color: $secondaryS1;
    border-color: $secondaryS1;
  }
}

.onlyForYou_card_progress_container {
  position: absolute;
  left: 8%;
  bottom: 11%;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  label {
    display: flex;
    color: $greyG8;
    width: 100%
  }

  .onlyForYou_card_progress {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-top: -5%;

    .onlyForYou_card_gift_icon {
      position: relative;
      top: 8%;
      margin-inline-start: -14px;
      z-index: 5;
      border-radius: 100%;
      background-color: $greyG6;
    }

    .onlyForYou_card_progress_img {
      height: 40px;
      width: 40px;
      filter: $primaryP2Filter;
    }
    .ant-progress-inner {
      background:  $greyG6;

      .ant-progress-bg {
        background: $secondaryS1;
      }
    }
  }

}

@media (max-width: 1199px) and (min-width: 992px) {
  .onlyForYou_card_describe {
    height: 34px * 5;
  }
  .onlyForYou_card_tag {
    padding-left: 8px;
    padding-right: 8px;
  }
  button.onlyForYou_card_button {
    height: 40px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .onlyForYou_card_describe {
    height: 28px * 5;
  }
  .onlyForYou_card_tag {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  button.onlyForYou_card_button {
    height: 40px;
  }
}
