@import "../../frontend_common/Theme.scss";

.home-retain-membership-section {
  margin: 0;
  background-color: $greyG8;
  width: 100%;
  height: 140px;
  padding: 24px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid $primaryP2;

  .retain-title {
    display: flex;
    justify-content: space-between;

    >div>img {
      width: 18px;
      height: 16px;
      filter: $primaryP2Filter;
      cursor: pointer;
    }
  }

  .retain-tpe-progress {
  }

  .retain-progress {
    margin: 0;

    .ant-progress-inner {
      background-color: $greyG5;
    }

    .ant-progress-bg {
      background-color: $primaryP2 !important;
    }
  }

  .spend-to-retain-text {
    >p {
      display: inline-block;
      margin: 0;

      color: $primaryP2;
    }
  }
}

.retain-modal .modal-dialog {
  .modal-content {
    min-width: 624px;
  }
}

.retain-modal-body.modal-body {
  padding: 32px 24px;
  min-width: 624px;

  .got-it-btn {
    margin-top: 40px;
    display: flex;
    justify-content: end;
  }
}

.retain-modal-title {
  color: $greyG1;
}

.retain-modal-content {
  color: $greyG1;
  margin-top: 8px;
}

.retain-modal.mobile-view {
  .modal-dialog {
    .modal-content {
      min-width: auto;
      max-width: 312px;
      margin: 0 auto;
    }
  }
  .retain-modal-body.modal-body {
    width: auto;
    min-width: auto;
    max-width: 312px;
    text-align: center;
    padding: 24px;

    .got-it-btn {
      justify-content: center;

      > button {
        width: 100%;
      }
    }
  }
}