@import "./src/frontend_common/Theme.scss";

div.onlyForYou_web_container {
  position: relative;
  width: 100%;
  padding: 65.1% 0 0;
}

div.onlyForYou_web_background_blue {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 56.8%;
  height: 100%;

  background-color: $primaryP2;
}

div.onlyForYou_web_title {
  position: absolute;
  top: 7.5%;
  left: 3.3%;
  color: $primaryP1;
}

div.onlyForYou_web_slider_container {
  position: absolute;
  width: 100%;
  height: 67%;
  top: 18.6%;
  right: 0;
  left: 0;
  overflow: hidden;

  .carousel {
    height: 100%;
  }

  .carousel-inner {
    height: 100%;
  }

  .carousel-item {
    height: 100%;
  }

  a.carousel-control-prev,
  a.carousel-control-next {
    width: 44px;

    &:focus {
      opacity: 0.5;
    }

    &:focus:active,
    &:hover {
      opacity: 1;
    }
  }

  .onlyForYou_web_slider_leftArrow {
    display: var(--carouselPrevControlShow, none);
    width: 44px;
    height: 44px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-left: 32px;
  }

  .onlyForYou_web_slider_rightArrow {
    display: var(--carouselNextControlShow, none);
    width: 44px;
    height: 44px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    margin-right: 32px;
  }

  .carousel-control-prev {
    pointer-events: var(--carouselPrevButtonEvent, none);
  }

  .carousel-control-next {
    pointer-events: var(--carouselNextButtonEvent, none);
  }
}

div.onlyForYou_web_carousel_body {
  height: 100%;
  width: 80.7%;
  margin: auto;
}
