@import "../../frontend_common/Theme.scss";

.app_container:has(.reward-status-mobile-page-container) {
    background-color: $greyG7;
}

.reward-status-mobile-page-container {

    .reward_status_table_view {
        .reward_status_table_head {
            border-radius: 8px 8px 0 0;
            padding: 12px;
        }

        .reward-status-table-body {
            overflow-y: scroll;
            min-height: 68px;
            max-height: calc(100vh - 65px - 48px - 46px - 24px - 126px);
            background: $greyG8;

            &::-webkit-scrollbar {
                width: 3px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $primaryP2;
                border-radius: 2px;
            }

            .reward-status-table >tbody>tr {
                padding: 16px 12px;

                .item_name {
                    width: 62%;
                    min-width: auto;
                    max-width: none;

                    .type-icon {
                        min-width: 24px
                    }
                }
            }
            .reward-status-table >tbody>tr.tr-stamp {
                padding: 11px 12px 8px 12px;

                .item_name.stamp-status {
                    max-width: 65%;
                    width: 65%;
                }
            }
        }
    }
}