@import "../../frontend_common/Theme.scss";

.selected_store_section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0 24px 5px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
.select_store_name {
  color: $greyG1;
  margin-bottom: 0;
}
.couopn_detail_store_title {
  color: $primaryP1;
}
.location_icon {
  width: 20px;
  height: 20px;
}
div.store_media {
  margin: 0px;

  .location {
    margin-bottom: 0;
  }
}
.store_media > div {
  display: flex;
}

@media (max-width: 1023px) {
  .selected_store_section {
    padding: 0 0 24px 5px;

    > div{
      gap: 4px;
    }
  }
  div.store-top-dividing {
    display: none;
  }
}