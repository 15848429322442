
@import './src/frontend_common/Theme.scss';

.inbox_list_messageItem {
  height: 164px;
  width: 100%;
  padding: 24px;
  display: flex;
  cursor: pointer;
  background-color: $greyG8;
  &:hover {
    background-color: $greyG6;
  }
  &.active {
    background-color: $greyG6;
  }
  .messageItem_checkbox {
    margin-right: 12px;
    height: 100%;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    .message_checkbox {
      height: 24px;
      width: 24px;
    }
  }
  .messageItem_chevron {
    height: 100%;
    width: 16;
    display: flex;
    justify-content: center;
    align-items: center;
    .chevron_right_image {
      height: 16px;
      width: 16px;
    }
  }
  .messageItem_container {
    position: relative;
    flex: 1;
    height: 100%;
    width: 92%;
    .messageItem_title_area {
      display: flex;
      height: 22px;
      align-items: center;
      .messageItem_title {
        color: $greyG1;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .messageItem_redDot {
        display: flex;
        width: 8px;
        height: 8px;
        margin-right: 10px;
        .redDot_image {
          width: 8px;
          height: 8px;
          object-fit: cover;
        }
      }
    }
    .messageItem_content_area {
      margin-top: 8px;
      width: 100%;
      height: 84px;
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      .message_photo {
        width: 112px;
        height: 84px;
        border-radius: 8px;
      }
      .messageItem_content_text {
        width: auto;
        height: 84px;
        flex: 1;
        display: flex;
        flex-flow: column;
        &.has_photo {
          margin-left: 12px;
          width: 100px;
        }
        .messageItem_content {
          height: 48px;
          width: auto;
          color: $greyG3;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .messageItem_date {
          height: 22px;
          position: absolute;
          bottom: 0px;

          color: $greyG4;
        }
      }
    }
  }
}
